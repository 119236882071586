import queryString from 'query-string'

/*
  launchpad variable: qs
  categories: routing
  Initialized as the query string parsed as a js object, which can be modified
  and persisted to the query string via the `setQS` function. Import via:
```
import { qs } from 'launchpad'
```
*/
export let qs = queryString.parse(location.search)


/*
  launchpad function: setQS
  categories: routing
  A function to set variables in the query string. Similar in format to react's
  `setState`, e.g. `setQS({name: value})`. Import via:
```
import { setQS } from 'launchpad'
```
  arguments:
    object - (object) an object containing keys/values to push to the query string (leave blank to reset value)
*/
export const setQS = object => {
  qs = queryString.parse(location.search)
  object = object || {}
  const { history } = require('app')
  const { pathname } = window.location
  let diff = false
  Object.keys(object).forEach(x => {
    if(qs[x] != object[x]) diff = true
  })
  if(diff) {
    qs = {...qs, ...object}
    history.push(`${pathname}?${queryString.stringify(qs)}`)
  }
}
