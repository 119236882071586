/*
  launchpad overview: routing

  Most basic routing is done automatically (see CMS section), manually adding routes
  can be done in `/src/config/index.jsx`. Consider using the `<Page>` component,
  following the instructions provided in the CMS section to take advantage
  of the automatic CMS integration.
*/


import React from 'react'
import { Route } from 'react-router-dom';
import { loadPages } from 'pages/admin/PageManager'
// import CustomPage from '../components/templates/CustomPage'
import Home from 'pages/Home'
import { apiGet, setGlobal } from 'launchpad'


/* ==========================================
   CUSTOM ROUTING
  ========================================== */


/*
  launchpad variable: customRoutes
  categories: routing
  A variable containing any custom or ambiguous routes. When
  adding new routes, you can just add to this array. Routes will be rendered
  inside a `react-router` `<Switch>`, all of the standard `react-router` props
  apply. these routes will be automatically combined with CMS routes. If
  creating a single route which doesn't need ambiguous matching, consider just
  creating a file in the `components/pages` directory (see CMS section), which
  will automatically create a page, add it to the CMS's page index, and
  automatically implement code splitting.
*/

// manually add static routes
// NOTE: these routes will override automatic (page index) routes, use with caution
export const customRoutes = [
  <Route exact path="/" component={Home}/>
]



/*
  launchpad variable: refreshFunctions
  categories: routing, CMS
  This function is used to register an async function that should be called when calculating
  which routes are available to the CMS. Typically it will involve making an API
  call, and then generating a list of routes from the data that's returned.
  This approach is much less performant than statically adding routes, since these
  async functions must complete before the first render. However, when adding
  routes that depend upon dynamic data, such as blog posts or admin configuration,
  calls to this function allow dynamic routes to be added and integrated with
  other CMS functionality (e.g. editable menus)
*/

// add dynamic routes based on database or other asynchronously loaded data
export const refreshFunctions = [

  // NOTE: DO NOT REMOVE THIS, it loads the custom pages, left here as an example
  async () => {
    const pages = await loadPages(true) || []
    setGlobal({customPages: pages})
    let custom_routes = {}
    for(let p of pages) {
      if(!p.url.startsWith('/')) p.url = '/'+p.url
      custom_routes[p.url] = {type: 'custom', title: p.title, component: () => <CustomPage page={p} />}
    }
    return custom_routes
  },

]
