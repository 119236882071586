import React, { useState, useEffect } from 'react'
import { setGlobal, getGlobal, notify } from 'launchpad'
import { sortProjects } from 'pages/WebApp/tools'
import { getProjects } from 'pages/WebApp/tools/sync'

export const getProject = () => {
  return new Promise(resolve => {
    setGlobal({
      selectingProject: true,
      selectingProjectCallBack: resolve
    })
  })
}

export const ProjectSelector = () => {
  const [ selected, setSelected ] = useState(null)
  const { selectingProject, selectingProjectCallBack } = getGlobal()
  const projects = getProjects()

  useEffect(() => {
    setGlobal({ selectedProject: null })
  }, [])

  useEffect(() => {
    setSelected(null)
  }, [ selectingProjectCallBack ])

  const cancel = () => {
    selectingProjectCallBack(null)
    setGlobal({ selectingProject:false, selectedProject: null })
  }

  const commitSelection = () => {
    if(!selected) return notify('Please select a project to continue')
    selectingProjectCallBack(selected)
    setGlobal({ selectingProject: false, selectedProject: selected })
  }

  const sorted = sortProjects(projects || [], 'name-down')

  return <div className={`project-selector ${selectingProject && 'open'}`}>
    <div className={`project-selector-overlay ${selectingProject && 'open'}`}>
      <div className='project-selector-wrapper'>
        <h4>Select A Project</h4>

        <div className='project-selector-projects'>
          {sorted.map(p => {
            const active = selected && selected.id == p.id
            return <div
              key={p._id}
              className={`selectable-project ${active && 'active'}`}
              style={{ backgroundImage: `url(${p.thumbnail || p.url})` }}
              onClick={() => setSelected(p)}
            >
              {p.name} <i className='fa fa-check' />
            </div>
          })}
        </div>
        <div className='dialog-buttons'>
          <button onClick={cancel}>Cancel</button>
          <button className='proceed' onClick={commitSelection}>
            Use Selected <span className='fa fa-chevron-right' />
          </button>
        </div>
      </div>
    </div>
  </div>
}
