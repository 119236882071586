import { getGlobal, setGlobal } from 'launchpad'

const requireUpgrade = () => {
  const { user } = getGlobal()
  if(user && user.access_level == 'basic' && !user.role) {
    setGlobal({ showUpgrade: true })
    return true
  } else {
    return false
  }
}

export default requireUpgrade
