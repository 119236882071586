import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import requireUpgrade from './requireUpgrade'


export default async function generateKMZ(project) {
  if(requireUpgrade()) return
  const pname = project.name.replace(/ /g, '_')
  const zip = JSZip()

  let kml = `<?xml version="1.0" encoding="UTF-8"?>
<kml xmlns="http://www.opengis.net/kml/2.2">
<Document>
  <name>${project.name}</name>
`

  for(let [ idx, img ] of project.images.filter(x => !!x.latitude).entries()) {
    const url = img.url && img.url.startsWith('http')
      ? img.url
      : `${window.location.protocol}//${window.location.host}${img.url}`
    kml += `
  <Placemark>
    <name>Image ${idx + 1}</name>
    <Point>
      <coordinates>${img.longitude},${img.latitude}</coordinates>
    </Point>
    <Snippet><![CDATA[${img.description}]]></Snippet>
    <description><![CDATA[<!DOCTYPE html>
      <html>
        <head></head>
        <body>
          <img style="max-width: 480px" src="${url}" />
          <p>${img.description}</p>
        </body>
      </html>]]></description>
  </Placemark>`
  }
  kml += `
</Document>
</kml>`
  console.log(kml)
  zip.file(`${pname}.kml`, kml)
  const blob = await zip.generateAsync({ type: 'blob' })
  saveAs(blob, `${pname}_${new Date().toJSON().slice(0,10)}.kmz`)
}
