import React, { useEffect } from 'react'
import { Page, getGlobal, Image } from 'launchpad'
import { loadPages } from 'pages/admin/PageManager'
import { Link, Title } from 'widgets'
import Fade from 'react-move-stuff'

// blog listing page
export default function Blog() {
  useEffect(() => {
    loadPages()
  }, [])

  // only show pages denoted as blog posts which have been published
  const pages = (getGlobal('customPages') || [])
    .filter(x => x.page_type == 'article' && x.published)
    .sort((a, b) => a.date_created > b.date_created ? -1 : 1)

  return <Page id="articles">
    <div className="container">
      <Title>Press, Articles & Blogs</Title>
      {pages.map(x => 
        <Post key={x._id} post={x} />
      )}
    </div>
  </Page>
}

export const Post = ({ post }) => {
  const date = new Date(post.posted_date || post.date_created).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

  // extract excerpt from page if possible
  const snip = getGlobal('snips').filter(x => {
    return x.page == post._id && x.name.includes('content')
  })[0]
  const re = /<p>([^<]*)<\/p>/g
  const matches = (snip && snip.content || '').match(re)
  const paragraphs = (matches || []).map(x => x.replace(/<p>|<\/p>/g, '')).filter(x => !!x)

  const contentExcerpt =
    paragraphs[0] &&
    paragraphs[0]
      .split(' ')
      .slice(0, 35)
      .join(' ') + ' ...'
  const excerpt = post.excerpt || contentExcerpt || ''
  return <Fade up>
    <Link href={post.url} key={post._id} className="article-listing">
      <div className="preview">
        <Image page={`article-${post._id}`} name="article-poster" />
      </div>
      <div className="details">
        {/* <p>
          {/* <span className="author">{post.authorData && post.authorData.name}</span>
          {' — '}
          <span className="date">{date}</span>
        </p> */}
        <h3>{post.title}</h3>
        <span className="date">{date}</span>
        <p className="excerpt" dangerouslySetInnerHTML={{ __html: excerpt }} />
        <a className='read-more'>Read More</a>
      </div>
    </Link>
  </Fade>
}

// launchpad_url: /articles
