import React, { useState, useEffect, useRef } from 'react'
import { setGlobal, getGlobal, Modal, apiPost } from 'launchpad'
import { Switch, Collapsible, Select } from 'widgets'
import { useSetState } from 'launchpad/helpers'
import { PhotoMap } from 'pages/WebApp/MapPanel'

export const getPDFOptions = () => {
  return new Promise(resolve => {
    setGlobal({ selectingPDFOptions: true, selectingPDFCallBack: resolve })
  })
}

const defaultState = {
  mapType: 'roadmap',
  showOrientation: true,
  coords: null,
  options: {
    theme: 'default',
    map: true,
    notes: true,
    date: true,
    location: true,
    size: 'letter',
    orientation: 'portrait'
  }
}

export function PDFOptions() {
  const [ state, _setState ] = useState(defaultState)

  const setState = update => _setState(s => ({ ...s, ...update }))

  const preview = useRef()
  const loadedPreview = useRef()

  const { mapType, showOrientation, options, coords } = state
  const { selectingPDFOptions, selectingPDFCallBack, selectedPDFPhotos } = getGlobal()

  const setOptions = (op) => {
    const newOps = { ...options, ...op }
    setState({ options: newOps })
    // getPreview(newOps)
  }

  const red = `#ba3333`

  const cancel = () => setGlobal({ selectingPDFOptions: false, selectingPDFCallBack: null })

  const ops = { ...options, mapType, showOrientation, coords: JSON.stringify(coords) }

  const confirm = () => {
    selectingPDFCallBack({ ...state, options: ops })
    cancel()
  }

  const getPreview = async () => {
    if(preview.current) {
      let qs = '?'
      for(let key of Object.keys(ops)) {
        qs += `${key}=${encodeURIComponent(ops[key])}&`
      }
      // console.log('updating preview')
      let url = `/preview-pdf${qs}`
      if(loadedPreview.current != url+JSON.stringify(selectedPDFPhotos)) {
        loadedPreview.current = url+JSON.stringify(selectedPDFPhotos)
        const p = preview.current
        p.onload = () => {
          apiPost(url, { images: selectedPDFPhotos }).then(data => {
            p.contentWindow.document.write(data)
          })
        }
        p.contentDocument.location.reload(true)
      }
    }
  }

  useEffect(() => {
    if([ 'c-sheet', 'd-sheet', 'e-sheet' ].includes(options.size)) {
      setOptions({ orientation: 'landscape' })
    } else {
      setOptions({ orientation: 'portrait' })
    }
  }, [ options.size ])


  const photoString = JSON.stringify(getGlobal('selectedPhotos'))

  useEffect(() => {
    setState(defaultState)
  }, [ photoString ])

  useEffect(() => {
    // wait until iframe render
    setTimeout(getPreview, 1000);
  }, [ selectingPDFOptions ])

  useEffect(() => {
    getPreview()
  }, [ JSON.stringify(options), photoString, selectedPDFPhotos, mapType, showOrientation, JSON.stringify(coords) ])

  return <Modal
    id='pdf-options'
    open={selectingPDFOptions}
    onClose={() => setGlobal({ selectingPDFOptions: false })}
  >
    <div className='pdf-options-modal'>
      <div className='configuration'>
        <h4>Generate PDF</h4>
        <div className='options-grid'>

          <label className='double'> Layout: </label>
          <div className='field double'>
            <Select value={options.theme} onChange={theme => setOptions({ theme })}>
              <option value='default'>Collage</option>
              <option value='photo-feature'>Storyboard</option>
              <option value='poster'>Poster</option>
            </Select>
          </div>



          <label className='double'>Sheet Size:</label>
          <div className='field double'>
            <Select value={options.size} onChange={size => setOptions({ size })}>
              <option value='letter'>Letter (8.5" x 11")</option>
              <option value='legal'>Legal (8.5" x 14")</option>
              <option value='ledger'>Tabloid/Ledger (11" x 17")</option>
              <option value='c-sheet'>C sheet (18" x 26")</option>
              <option value='d-sheet'>D sheet (24" x 36")</option>
              <option value='e-sheet'>E sheet (30" x 42")</option>
            </Select>
          </div>

          <label className='double'>Orientation:</label>
          <div className='field double'>
            <div className='options'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp; Portrait</span>
              <Switch
                checkedIcon={false}
                uncheckedIcon={false}
                onColor={red}
                checked={options.orientation == 'landscape'}
                onChange={v => setOptions({ orientation: v ? 'landscape' : 'portrait' })}
              />
              <span>Landscape</span>
            </div>
          </div>

          <label>
          Show Coordinates:
          </label>
          <div className='field'>
            <div className='options'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp; Off</span>
              <Switch
                onColor={red}
                checked={options.location}
                onChange={v => setOptions({ location: v })}
              />
              <span>On</span>
            </div>
          </div>

          <label>
          Show Date:
          </label>
          <div className='field'>
            <div className='options'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp; Off</span>
              <Switch
                onColor={red}
                checked={options.date}
                onChange={v => setOptions({ date: v })}
              />
              <span>On</span>
            </div>
          </div>

          <label>
          Show Notes:
          </label>
          <div className='field'>
            <div className='options'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp; Off</span>
              <Switch
                onColor={red}
                checked={options.notes}
                onChange={v => setOptions({ notes: v })}
              />
              <span>On</span>
            </div>
          </div>

          <label>
          Show Maps:
          </label>
          <div className='field'>
            <div className='options'>
              <span>&nbsp;&nbsp;&nbsp;&nbsp; Off</span>
              <Switch
                onColor={red}
                checked={options.map}
                onChange={v => setOptions({ map: v })}
              />
              <span>On</span>
            </div>
          </div>
        </div>


        <Collapsible open={options.map}>
          <div className='options-grid'>
            <label>
              <span>Map type: </span>
            </label>
            <div className='field'>
              <div className='options'>
                <span>Road</span>
                <Switch
                  onColor={red}
                  offColor={red}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  checked={mapType == 'satellite'}
                  onChange={v => setState({ mapType: v ? 'satellite' : 'roadmap' })}
                />
                <span>Satellite</span>
              </div>
            </div>

            <label>
          Show Orientation:
            </label>
            <div className='field'>
              <div className='options'>
                <span>&nbsp;&nbsp;&nbsp;&nbsp; Off</span>
                <Switch
                  onColor={red}
                  checked={showOrientation}
                  onChange={v => setState({ showOrientation: v })}
                />
                <span>On</span>
              </div>
            </div>
          </div>
          <Collapsible open={options.theme != 'poster'}>
            <div className='map-preview pdf-map'>
              <div className='label'>
              Set Altitude and Center
              </div>
              {selectingPDFOptions && <PhotoMap
                photos={getGlobal('selectedPhotos') || []}
                mapType={mapType}
                showOrientation={showOrientation}
                onMove={coords => setState({ coords })}
              />}
            </div>
          </Collapsible>
        </Collapsible>

      </div>

      <div className={`preview-container ${options.orientation}`}>
        <h3 className='title'>Preview</h3>
        <div className='preview-wrapper'>
          {selectingPDFOptions && <iframe src='about:blank' className={`preview ${options.size}`} ref={preview} />}
        </div>
        <p className='notes'>
            Note: PDF preview is an approximation, it may differ from the final result
            depending on your current browser window width, selected paper size,
            and other print-specific conditions in play when generating the PDF.
        </p>
      </div>
    </div>

    <div className='dialog-buttons'>
      <button onClick={cancel}>Cancel</button>
      <button className='proceed' onClick={confirm}>
          Generate PDF <span className='fa fa-chevron-right' />
      </button>
    </div>
  </Modal>
}
