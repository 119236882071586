import { getGlobal, PageContext, DataStore, Image, Snip, Modal, TextEditor } from 'launchpad';
import ContentEditable from 'react-contenteditable';
import React from 'react';
import styled from 'styled-components'
import theme from 'styles/theme.js';
import Fade from 'react-move-stuff';
import { OptionsPanel, LayoutSelector, Section } from 'sections'
import { CheckBox, Collapsible } from 'widgets'

const BlockOptions = DataStore('TableOptions')

const borderColor = 'rgba(0,0,0,.1)'
const Container = styled.div`
  padding: 20px;
  table {
    width: 100%;
    max-width: 1200px;
    margin: 15px auto;
    border-top: 1px solid ${borderColor};
    border-right: 1px solid ${borderColor};
    p {
      margin: 0;
      padding: 0;
      color: inherit;
    }
    tr {
      border-bottom: 1px solid ${borderColor};
      th {
        background: rgba(0,0,0,.1);
      }
      th, td {
        padding: 5px 10px;
        border-left: 1px solid ${borderColor};
      }
    }
  }
`

class Class extends React.Component {

  state = {
    edit: false,
    // only used when editing
    options: false
  }

  edit = (obj) => {
    const updated = Object.assign({}, this.props.options, obj)
    this.props.onEdit(updated)
  }

  getOptions = () => {
    if(this.state.options) return this.state.options
    const o = BlockOptions.getAll()
    let options = o.find(op => {
      return op.page == this.props.page && op.name == this.props.name
    })
    return options || {page: this.props.page, name: this.props.name}
  }

  setOptions = (options) => {
    const currentOptions = this.getOptions()
    let newOptions = Object.assign({}, currentOptions, options)

    // load into state while editing
    this.setState({options: newOptions})

    BlockOptions.update(currentOptions.id, newOptions)
  }

  getMatrix = (options) => {
    let matrix = []
    for (let y = 1; y <= options.rows; y++){
      let row = []
      for (let x = 1; x <= options.cols; x++) {
        row.push({x, y})
      }
      matrix.push(row)
    }
    return matrix
  }

  setCol = (v, x, y) => {
    const d = this.props.defaultOptions
    let data = this.getOptions().data || {}
    data = Object.assign((d && d.data) || {}, data)
    // console.log('pre', data)
    if(!data[x]){
      data[x] = {y: v}
    } else {
      data[x][y] = v
    }
    // console.log('post', data)
    this.setOptions({data})
  }

  render() {
    const savedOptions = this.getOptions()
    const { edit } = this.state
    const defaultOptions = Object.assign({rows: 3, cols: 3, header: true, data: {}}, this.props.defaultOptions)
    let options = Object.assign({}, defaultOptions, savedOptions)
    options.rows = isNaN(options.rows) || options.rows < 1 ? 1 : options.rows
    options.cols = isNaN(options.cols) || options.cols < 1 ? 1 : options.cols
    const displayedSize = {
      y: typeof savedOptions.rows == 'undefined' ? options.rows : savedOptions.rows,
      x: typeof savedOptions.cols == 'undefined' ? options.cols : savedOptions.cols,
    }
    let headerRow = []
    for(let x = 1; x <= options.cols; x++){
      headerRow.push({x, y: 'h'})
    }

    const rowProps = {
      onChange: (v, x, y) => this.setCol(v, x, y),
      data: options.data
    }

    return <Fade up>
      <Section editing={this.state.edit}>
        <Container className='table-container'>
          <table>
            <tbody>
              {options.header && <TableRow header row={headerRow} {...rowProps} />}
              {this.getMatrix(options).map(r => <TableRow key={r[0].y} row={r} {...rowProps} />)}
            </tbody>
          </table>

          {getGlobal('is_admin') && <div className='edit-button'>
            <button className='material-icons' onClick={()=>this.setState({edit: !edit})}>{edit ? 'close' : 'edit'}</button>
          </div>}
        </Container>
      </Section>

      {getGlobal('is_admin') && <OptionsPanel open={this.state.edit} onClose={()=>this.setState({edit: false})}>
          <h3>Table Options</h3>
          <div className='option-group'>
            <div className='option-row'>
              <div>
                <span className='row-label'>Rows</span>
                <input placeholder='Leave empty to hide Button 1' type='number' value={displayedSize.y} onChange={e => this.setOptions({rows: e.target.value})}/>
              </div>
              <div>
                <span className='row-label'>Columns</span>
                <input placeholder='Leave empty to hide Button 1' type='number' value={displayedSize.x} onChange={e => this.setOptions({cols: e.target.value})}/>
              </div>
              <div style={{justifyContent: 'center'}}>
                <span className='row-label'>Add Header</span>
                <CheckBox checked={options.header} color='primary' onChange={(e, v) => this.setOptions({header: v})} />
              </div>
            </div>
          </div>
      </OptionsPanel>}
    </Fade>
  }
}

const TableRow = (props) => {
  const T = props.header ? 'th' : 'td'
  return <tr>
    {props.row.map(col => <T key={`${col.x}${col.y}`}>
      <TextEditor value={(props.data && props.data[col.x] && props.data[col.x][col.y]) || ''} onChange={v=>props.onChange(v, col.x, col.y)} />
    </T>)}
  </tr>
}


export const Table = (props) => <PageContext.Consumer>
  { context => <Class {...props} page={props.page || context.pageId} /> }
</PageContext.Consumer>
