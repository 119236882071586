import React, { useEffect } from 'react'
import { actives, deletePhoto } from './tools'
import { setGlobal, getGlobal } from 'launchpad'
import { useSetState } from 'launchpad/helpers'
import moment from 'moment'
import { Collapsible } from 'widgets'


// const update = debounce(description => {
//   const {activeImage} = actives()
//   updateImage(Object.assign(activeImage, {description}))
// }, 100)


export default function PhotoDetails({ onDesc, onAccept }) {
  const [ state, setState ] = useSetState({
    desc: '',
    monitoring: true,
    activeImageId: null
  })

  const { user } = getGlobal();

  const { activeImage } = actives()
  console.log(activeImage);
  const { latitude, longitude, imageId, description } = activeImage || {}
  const { monitoring, desc, activeImageId } = state

  useEffect(() => {
    const d = description || ''
    setState({ desc: d, activeImageId: imageId })
    onDesc(d)
  }, [ imageId ])


  const setDesc = e => {
    const desc = e.target.value
    setState({ desc })
    onDesc(desc)
    // update(desc)
  }

  const editing = (getGlobal('editingImage') && getGlobal('editingImage').imageId) == (activeImage && activeImage.imageId)
  // console.log(getGlobal('editingImage'), activeImage)

  const basicUserFromOrg = user.role && user.role == "User"
  const allowChanges = !basicUserFromOrg || user._id.$oid === activeImage?.user;
  console.log(activeImage);

  return <div className='photo-details'>
    {activeImage && <div className='details-wrapper'>
      {activeImage.capture_method == 'camera' ? 'Photo Date' : 'Imported On' }
      : {activeImage.timestring || moment(activeImage.created).format('M/D/YYYY h:mm a')}<br />
      Coordinates: {latitude
        ? <span>{latitude.toFixed(4)}&deg;N, {longitude.toFixed(4)}&deg;W</span>
        : <span>Select a location</span>
      }
      <br />
      {activeImage.capturer && <>
        {activeImage.capture_method == 'camera' ? 'Photographer' : 'Imported By' }: {activeImage.capturer} <br />
      </>}
      { (editing || activeImage.description) && 'Description:'}
      <Collapsible open={!editing}>
        <p>{activeImage.description}</p>
      </Collapsible>
      <Collapsible open={editing}>
        <textarea
          value={monitoring ? activeImage.description : desc}
          onChange={setDesc}
          onFocus={() => setState({ monitoring: false })}
          onBlur={() => setState({ monitoring: true })}
        />
      </Collapsible>
      <div className='tools'>
        <Collapsible open={editing}>
          <div className='fa fa-times' onClick={() => setGlobal({ editingImage: null })} />
          <div className='fa fa-check' onClick={onAccept} />
        </Collapsible>
        { allowChanges && <>
            <Collapsible open={!editing}>
              <div className='fa fa-pencil' onClick={() => {
                setGlobal({ editingImage: editing ? null : activeImage })
              }} />
            </Collapsible>
            <div className='fa fa-trash' onClick={() => deletePhoto(activeImage)} />
          </>
        }
      </div>
    </div>}
  </div>
}
