import React, { useState, useRef, useEffect } from 'react'

export function useSetState(d) {
  const [state, _setState] = useState(d)
  return [state, (obj) => _setState({...state, ...obj})]
}

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
