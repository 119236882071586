import Fade from './Fade.jsx';
export {FadeBlocks, Animator, ScrollTrigger, FadeContainer, getFadeTarget} from './Fade.jsx';
export default Fade

/*
var Fade = require('./Fade.js')
module.exports = Fade

module.exports.ScrollTrigger = Fade.ScrollTrigger
module.exports.Animator = Fade.Animator
*/
