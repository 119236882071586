import React from 'react'
import ReactDOM from '@hot-loader/react-dom'
import { hot } from 'react-hot-loader'

import { Router, Switch, Route } from 'react-router-dom'

import history from 'launchpad/history'
export { default as history } from 'launchpad/history'

import { launchpadInit, setGlobal, getGlobal, notify,
  applyMeta, Modal, ModalContainer, checkAuth, Loading } from 'launchpad'
import { OptionsContainer } from 'sections/options'

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'

import { ConfirmModal, NotificationContainer, LoadIndicator,
  ImageUploader } from 'launchpad/helpers'
import { FadeContainer, getFadeTarget } from 'react-move-stuff'

import { refreshRoutes } from 'launchpad/routes'
import AutoRoutes from 'launchpad/AutoRoutes'

import Header from 'layout/Header'
import Footer from 'layout/Footer'

import loadable from '@loadable/component'

// import ContextPane from 'launchpad/admin/ContextPane'
// import AdminBar from 'launchpad/admin/AdminBar'
// import MediaLibrary from 'launchpad/admin/MediaLibrary'
import { loadUser } from 'pages/WebApp/UserLogin'

// not sure why these need to be imported here... some kind of parcel bug
const img = require('images/map-direction-0.png')
const img2 = require('images/mapcamera_red.png')


// import{setConfig} from 'react-hot-loader'
// setConfig({logLevel: 'debug'})



// scroll to the top when navigating so page changes aren't disorienting
history.listen((location, action) => {
  if(!location.hash) {
    window.scrollTo(0, 0)
    const ftgt = getFadeTarget()
    if (ftgt) ftgt.scrollTop = 0
    setTimeout(() => {
      window.scrollTo(0, 0)
      const ftgt = getFadeTarget()
      if (ftgt) ftgt.scrollTop = 0
    }, 300)
  }
  if(getGlobal('hamburgerOpen')) setGlobal({ hamburgerOpen: false })
  applyMeta()
  if(window.registerPageView){
    window.registerPageView()
  }
})


setGlobal({ editImages: true })


// a few keyboard shortcuts for common tasks and "super admin" functionality
function checkKeys(e) {
  if(getGlobal('is_admin') || getGlobal('doAdminToggle')){
    var evtobj = window.event ? event : e

    // use ctrl + e to activate global image editing
    if (evtobj.keyCode == 69 && evtobj.ctrlKey) {
      setGlobal({ editImages: !getGlobal('editImages') })
    }

    // use ctrl + e to activate global image editing
    if (evtobj.keyCode == 65 && evtobj.ctrlKey && evtobj.shiftKey) {
      if (getGlobal('is_admin') || getGlobal('doAdminToggle'))
        setGlobal({ is_admin: !getGlobal('is_admin'), doAdminToggle: true })
    }

    if (evtobj.keyCode == 71 && evtobj.ctrlKey) {
      const pane = getGlobal('app').contextPane
      if(pane && pane.showPageSelector) pane.showPageSelector()
    }
  }
}

document.onkeydown = checkKeys


const MUItheme = createMuiTheme({
  palette: {
    primary: {
      main: '#2196f3'
    },
    secondary: {
      main: '#555555'
    }
  },
  typography: {
    useNextVariants: true,
  },
})

// Main app class
class App extends React.Component {
  state = {
    is_admin: false
  }

  // contextPane = <span />
  // mediaLibrary = <span />
  // adminBar = <span />

  componentDidMount() {
    checkAuth()
    loadUser()
    refreshRoutes()
    setGlobal({ mainDomRoot: this.root, app: this }, false)
  }

  componentDidUpdate(prevProps, prevState) {
    if((getGlobal('is_admin') || getGlobal('user_access') == 'admin' ) && !this.state.is_admin){
      this.setState({ is_admin: true })
      // TODO: figure out why these aren't working...

      const MediaLibrary = loadable(() => import('launchpad/admin/MediaLibrary'))
      const ContextPane = loadable(() => import('launchpad/admin/ContextPane'))
      const AdminBar = loadable(() => import('launchpad/admin/AdminBar'))
      this.mediaLibrary = <MediaLibrary fallback={<Loading active={true}/>} />
      this.contextPane = <ContextPane fallback={<Loading active={true}/>} />
      this.adminBar = <AdminBar fallback={<Loading active={true}/>} />
    }
  }


  render() {
    // set conditions for "dev mode" (displays warnings to avoid accidental deployment)
    const devMode = false
    const is_admin = getGlobal('is_admin') || getGlobal('user_access') == 'admin'
    if(getGlobal('noAPIConnection')) return <div id='app-container'><div ref={el => this.root = el} className='offline'>
      <h2>Sorry, it looks like we're offline at the moment</h2>

      <p>We're either performing maintenance or experiencing technical difficulties. Either way, we'll be back shortly!</p>
    </div></div>
    return (
      <MuiThemeProvider theme={MUItheme}>
        <div id='app-container'>
          <Router history={history}>
            {/* maybe move to <LaunchpadWrapper /> or similar to provide classes */}
            <FadeContainer className={"root-wrapper "
              + (getGlobal('editImages') ? ' edit-images ' : '')
              + (getGlobal('openContext') && is_admin ? ' context-open ' : '')
              + (getGlobal('pageContext') || 'generic') + '-wrapper '
              + (is_admin ? ' is-admin' : ' test')}
            id='rootWrapper' ref={el => this.root = el && el.el} >
              <NotificationContainer />
              <ConfirmModal />
              <ImageUploader />
              {this.contextPane}
              {this.adminBar}
              {this.mediaLibrary}
              {/* <ContextPane /> */}
              {/* <AdminBar />
              <MediaLibrary /> */}
              <Header />
              <AutoRoutes />
              <Footer key={'footer'} />
              <OptionsContainer />
              <ModalContainer/>
              <LoadIndicator />
              {devMode && <div className='devMessage'>
                WARNING!! This app is in testing mode, please change global_config before pushing to production
              </div>}
            </FadeContainer>
          </Router>
        </div>
      </MuiThemeProvider>
    )
  }
}

export default hot(module)(App)
// export default App
//// if(module.hot) module.hot.accept()
