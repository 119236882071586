import React from 'react';
import {login, checkAuth, getGlobal} from 'launchpad';
import {history} from 'app'
import {Link, Input} from 'widgets'

export class AdminLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: false
    }
  }
  componentDidUpdate() {
    // redirect to home if logged in
    if(getGlobal('is_admin') || getGlobal('user_access') == 'admin'){
      history.replace('/')
    }
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value}
    );
  }
  handleSubmit(e) {
    e.preventDefault();
    login(this.state.email, this.state.password).then(() => {
      checkAuth((admin) => {
        if(admin){
          history.replace('/')
        } else {
          this.setState({error: true});
        }
      })
    });
  }

  render() {
    return (
      <main className="admin-login">
        <section className="container">
          <form className="login-form" onSubmit={e => this.handleSubmit(e)}>
            <h1><img src='/cp_favicon.png' /> LAUNCHPAD</h1>
            <Input
              type="text"
              name="email"
              label="Email"
              value={this.state.email }
              onChange={e => this.handleChange(e)}
            />
            <Input
              type="password"
              name="password"
              label="Password"
              value={this.state.password}
              onChange={e => this.handleChange(e)}
            />
            <button>Log in</button>
            <Link href='/admin/user-register'>Register</Link>
            <label id="login-fail" style={{display: (this.state.error ? 'block' : 'none')}}>Login failed. Please try again.</label>
          </form>
        </section>
      </main>
    );
  }
}
