import React from 'react';
import {firebase} from 'launchpad';
import {Slider, getMedia, reloadImageInstances, getGlobal, getImage, setImage,
  PageContext, confirm, reloadImages, Modal, notify } from 'launchpad';
import { Input } from './Input';
import { Link } from 'widgets';


let imagesLoaded = false


export class ImageGetter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: '',
      plusSize: '30px'
    }
  }

  componentDidMount() {
    // timeout is annoyingly necessary to make sure styles are applied before
    // width gets calculated
    setTimeout(() => {
      if(this.div){
        this.setState({plusSize: (this.div.offsetWidth / 1.5) + 'px'})
      }
    }, 10);
  }

  UNSAFE_componentWillUpdate(props) {
    if(!this.state.url && props.src){
      this.setState({url: props.src});
    }
  }

  getImage() {
    getMedia(image => {
      if(image){
        this.setState({url: image});
        if(typeof this.props.onChange === 'function'){
          this.props.onChange(image)
        }
      }
    })
  }

  removeImage(e) {
    e.stopPropagation();
    this.setState({url: ''})
    if(typeof this.props.onChange === 'function'){
      this.props.onChange('')
    }
  }

  render() {
    let tools = (
      <div className='image-tools text-right'>
        <div className='tool-container'>
          <button className='change material-icons' onClick={(e) => this.getImage()}>edit</button>
          <button className='remove material-icons' onClick={(e) => this.removeImage(e)}>close</button>
        </div>
      </div>
    )
    if(!this.state.url){
      tools = <div className='big-plus'><span className='material-icons' style={{fontSize: this.state.plusSize}}>add</span></div>
    }
    return <div ref={div => this.div = div} className='image-getter image-cover square' style={{backgroundImage: 'url('+this.state.url+')'}} onClick={() => this.getImage()}>
      <div className='tools'>
        {tools}
      </div>
    </div>
  }
}


/* launchpad component: Image
   categories: CMS, core
   An `Image` is a flexible, editable image that is automatically
   set up for user editing when a launchpad user is logged in

   props:
    page - (String) which page this image is displayed on, typically unnecessary as it can be picked up from the Page Context
    name - (String) a unique name for this piece of text (or a shared name for shared data)
    img - (Boolean) set this property to use an <img /> tag instead of the default div with background image
*/
export class ImageClass extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      hovered: false,
      editing: false,
      link: '',
      title: '',
      updated: false
    }
  }

  componentDidMount() {
    if(!imagesLoaded) {
      reloadImages()
      imagesLoaded = true
    }
  }

  componentDidUpdate() {
    const im = this.getBackground() || {}
    if(((im.link && !this.state.link) || (im.title && !this.state.title)) && !this.state.updated) {
      this.setState({link: im.link, title: im.title, updated:true})
    }
  }

  setImage(atts) {
    const page = this.props.page || this.props.prefix;
    setImage(page, this.props.name, Object.assign({}, this.getBackground() || {}, atts));
    if(typeof this.props.onChange === 'function') {
      this.props.onChange(atts.url)
    }
    this.forceUpdate()
  }

  getBackground() {
    const page = this.props.page || this.props.prefix;
    return getImage(this.props.name, page);
  }

  setBrightness(o) {
    let image = this.getBackground();
    const page = this.props.page || this.props.prefix;
    this.setState({brightness:o});
    setImage(page, this.props.name, {url: image.url, brightness: o});
    if(this.debounce){
      clearTimeout(this.debounce);
    }
    this.debounce = setTimeout(() => {
      // push changes
      this.setImage({url: image.url, brightness:o});
    }, 500);
  }

  editBackground(e, prevDef) {
    e.stopPropagation()
    if(prevDef) e.preventDefault()
    let thisImage = this.getBackground();
    let brightness = thisImage ? thisImage.brightness : 1;
    getMedia(
      (image) => {
        if(image) {
          let im = {url:image}
          if(thisImage && thisImage.brightness){
            im.brightness = thisImage.brightness
          }
          this.setImage(im)
        }
      }, thisImage.url
    )
  }

  removeBackground = (e) => {
    e.stopPropagation()
    e.preventDefault()
    confirm('Are you sure you want to remove this Image?', () => {
      this.setImage({url: '', brightness: 1})
    })
  }

  updateMeta = () => {
    this.setImage({
      link: this.state.link,
      title: this.state.title
    })
    notify('Image updated')
    this.setState({editing: false})
  }

  openModal = e => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({editing: true})
  }

  render(){
    let image = this.getBackground();
    let background = image ? image.url : '';
    let brightness = image && image.brightness ? image.brightness : 1;
    let mode = this.props.mode ? this.props.mode : 'cover';
    let slider = '';
    let button = '';
    let overlay = '';
    let doBrightness = this.props.dimmer
    if(getGlobal('is_admin')){
      if(doBrightness && background){
        slider = (
                  <Slider
                    className="dimmer"
                    icon='brightness_medium'
                    value={brightness}
                    min={.1}
                    max={1}
                    onChange={e => this.setBrightness(e)} />
                  );
      }
      button = ''; //<button className='material-icons' onClick={() => this.editBackground()}>photo</button>;
    }
    if(1 - brightness > 0){
      overlay = <div className='overlay' style={{opacity: (1 - brightness)}} />
    }
    let style = {}
    if(!this.props.img) style = background ? {'backgroundImage':'url("'+background+'")'} : (getGlobal('is_admin') ? {'background':'#ddd'} : {});
    let className = (this.props.className || '')+' editable-image image-'+mode;
    if(!background) className += ' empty'
    if(this.props.alwaysEdit) className += ' force-editable';
    const hovering = this.state.hovered && getGlobal('is_admin');
    const click = (this.props.useEditIcon || image.link) ? undefined : (e)=>this.editBackground(e);
    if(!this.props.useEditIcon){
      className = className + ' pointer-all';
    }

    const img = <div
      id={this.props.id}
      className={className}
      style={style}
      onClick={getGlobal('is_admin') ? click : undefined}
      onMouseEnter={()=>this.setState({hovered: true})}
      onMouseLeave={()=>this.setState({hovered: false})}
      >
        { image.title && !this.props.img && <span role='img' aria-label={image.title}/> }
      { overlay }
      { hovering && <div className='overlay hover-overlay' /> }
      { this.props.img && <img src={background} alt={image.title}/> }
      { !this.props.img && this.props.children }
      <div className='image-tools'>
        { slider }{ button }
      </div>
      { hovering && <div className='image-edit' onClick={e=>{e.stopPropagation(); e.preventDefault()}}>
        {background && <div className='image-remove-button' onClick={e=>this.removeBackground(e)}><em className='material-icons'>delete</em></div>}
        {<div className='image-configure-button' onClick={this.openModal}><em className='material-icons'>settings</em></div>}
        <div className='image-edit-button' onClick={e=>this.editBackground(e, true)}><em className='material-icons'>edit</em></div>
      </div>}
      {getGlobal('is_admin') && <Modal open={this.state.editing} onClose={()=>this.setState({editing: false})}>
        <Input placeholder='Link' value={this.state.link} onChange={e=>this.setState({link: e.target.value})}/>
        <Input placeholder='Title' value={this.state.title} onChange={e=>this.setState({title: e.target.value})}/>
        <button className='btn btn-primary' onClick={this.updateMeta}>Update</button>
      </Modal>}
    </div>
    return image.link
      ? <Link href={image.link}>{img}</Link>
      : img
  }
}

export const Image = (props) => <PageContext.Consumer>
  {data => <ImageClass {...props} page={props.page || data.pageId} />}
</PageContext.Consumer>
