import React from 'react';
import { styled, injectGlobal } from 'styled-components';

export const colors = {
  primary: '#21c87a',
  secondary: '#796eff',
  blue: {
    darkest: '#202B5D',
    dark: '#263F8F',
    mediumDark: '#1B45D5',
    mediumLight: '#3B65EF',
    light: '#8893CF',
    lightest: '#A8BCFD'
  }
}

const theme = {
  colors: colors
};

injectGlobal`
  body {
    /* background-color: ${theme.colors.primary}; */
  }
  a {
    color: ${theme.colors.blue.dark};
    &:hover, &:focus {
      text-decoration: none;
      color: ${theme.colors.blue.mediumLight};
    }
  }
  .btn {
    padding: 14px 28px;
    font-weight: 600;
    border-radius: 0;
    border: 0.5px solid #fff;
    &.btn-primary,
    &.btn-light {
      &&& {
        border: 0.5px solid #fff;
      }
    }
  }
  .btn-primary {
    background-color: ${theme.colors.blue.dark};
    color: #fff;
  }
  .btn-light {
    color: ${theme.colors.blue.dark};
    background-color: #fff;
  }
`;

export default theme;
