import React, { useState, useEffect } from 'react'
import { Image, Snip, getSnip, getGlobal, setGlobal, Page, DataStore } from 'launchpad'
import Fade, { FadeBlocks } from 'react-move-stuff'
import { Hero, CTA, ContentBlock } from 'sections'
import { Slideshow, Link, AppStoreButtons } from 'widgets'
import { useSetState, useInterval } from 'launchpad/helpers'
import { ScrollTrigger } from 'react-move-stuff'
import { history } from 'app'
import { Testimonial } from 'pages/Testimonials'

// const mapBG = 'test'
// const mapBG = require('images/map-bg.jpg')

const Testimonials = DataStore('testimonials')

const Feature = ({ fid }) => <div className='feature'>
  <Image name={`feature-${fid}`} />
  <Snip name={`feature-${fid}`} />
</div>

const pushHash = (h) => {
  if(!h) setGlobal({ homeSection: '' })
  if(window.location.hash != `#{h}`) {
    history.push(`/#${h}`)
  }
}

export default function Home() {
  const [ state, setState ] = useSetState({
    slide: 1,
    testimonialSlide: 1,
    doSlide: true
  })
  const testimonials = Testimonials.getAll()
  const { slide, doSlide, testimonialSlide } = state

  const { homeSection, customPages } = getGlobal()

  useEffect(() => {
    if(homeSection){
      const section = document.querySelector(`#home .${homeSection} .anchor`)
      if(section) section.scrollIntoView({ behavior: 'smooth' })
    }
  }, [ homeSection ])

  const next = () => {
    const next = state.slide + 1
    const testNext = state.testimonialSlide + 1
    if(doSlide) setState({ slide: next > 4 ? 1 : next })
    setState({ testimonialSlide: testNext > testimonials.length ? 1 : testNext })
  }

  let articles = (customPages || []).filter(x => x.page_type == 'article')
  articles.reverse()
  articles = articles.slice(0, 3)

  const { Post } = require('pages/Articles')

  useInterval(() => {
    next()
  }, 5000)

  const slides = [ 1, 2, 3, 4 ]
  return <Page id='home'>
    <FadeBlocks up>
      {/* <Hero half/> */}
      <div className='home-hero'
        onMouseEnter={() => setState({ doSlide: false })}
        onMouseLeave={() => setState({ doSlide: true })}
      >
        <Slideshow slide={slide} className='home-hero'>
          {slides.map(x => {
            const active = slide == x
            return <Image key={x} name={`slide-${x}`}>
              <div className='text' className={active ? 'text active' : 'text'}>
                <Snip name={`slide-${x}`} />
              </div>
            </Image>
          })}
        </Slideshow>
        <div className='buttons'>
          {slides.map(x => {
            return <div key={x} onClick={() => setState({ slide: x })} className={slide == x ? 'active' : ''} />
          })}
        </div>
      </div>

      <AppStoreButtons />

      <div className='keys features'>
        <div className='anchor' />
        <ScrollTrigger
          repeat
          exactPosition
          offset={300}
          onReset={() => pushHash('')}
          onTrigger={() => pushHash('features')}
        />
        <div className='container'>
          <Snip className='header' default='<h2>Key Features & Functionality</h2>' name='features-header'/>
          <p className='more-link'>
            <Link href='/features'>Click here for more info</Link>
          </p>
          <div className='grid c3'>
            {[ 1,2,3 ].map(x => <Feature key={x} fid={x} />)}
          </div>
        </div>
      </div>

      <Image id='videos' className='videos' name='lower-section'>
        <div className='anchor' />
        <ScrollTrigger
          repeat
          exactPosition
          offset={300}
          onReset={() => pushHash('features')}
          onTrigger={() => pushHash('videos')}
        />
        <div className='container'>
          <Snip name='video-header' />
          <div className='vid-columns grid c2'>
            <div>
              <Snip name='video-column-1' />
            </div>
            <div>
              <Snip name='video-column-2' />
            </div>
          </div>
        </div>
      </Image>

      <div className='testimonials'>
        <div className='container'>
          <Slideshow slide={testimonialSlide} className='home-testimonials'>
            {testimonials.map(t => <Testimonial key={t.id} testimonial={t} />)}
          </Slideshow>
        </div>
        <p><Link href='/testimonials'>Read More <i className='fa fa-chevron-right' /></Link></p>
      </div>

      <div className='articles container'>
        <h2>Industry Articles & Case Studies</h2>
        <p className='article-link'>
          <Link href='/articles'>
            Click here for more articles <i className='fa fa-chevron-right' />
          </Link>
        </p>
        <div className='article-container grid c3'>
          {articles.filter(x => x.published).map(article => <Post key={article.id} post={article} />)}
        </div>
      </div>

    </FadeBlocks>
  </Page>
}
