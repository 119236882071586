import React from 'react';
import {getGlobal, setGlobal, loadGlobal, createDoc, Spinner,
  updateDoc, deleteDoc, Modal, Collapsible, apiPost, helpers } from 'launchpad';
import _ from 'lodash';
import styled from 'styled-components';
import { titleCase, startLoad, stopLoad } from 'launchpad/helpers'
import Pica from 'pica/dist/pica.js'

/***************************************************
  UPLOAD HELPERS
****************************************************/

const pica = Pica();


export function getThumbUrl(src){
  let urlparts = src.split('.');
  let ext = urlparts.slice(-1)[0];
  if(ext == 'svg'){
    return src;
  }
  return urlparts.slice(0, -1).join('.') + '.thumbnail.' + ext
}

export class ImageUploader extends React.Component {
  componentDidMount() {
    setGlobal({ImageUploaderComponent: this})
  }

  state = {options: {}}

  upload = (cb, options) => {
    options = options || {}
    this.maxSize = options.maxSize
    this.cb = cb
    this.setState({options}, () => this.fileInput.click())
  }

  submit = (extension, base64) => {
    const options = this.state.options || {}
    if(options.skipUpload) {
      this.cb(base64)
      return
    }
    const l = startLoad()
    apiPost('/upload', {extension, base64, options: this.state.options}).then(data => {
      this.fileInput.value = ''
      this.cb(data)
    }).finally(()=>stopLoad(l))
  }

  readImage = e => {
    let extension = 'png'
    if(this.cb) {
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        if(!this.canvas) this.canvas = document.createElement('canvas')
        this.img.onload = () => {
          const {height, width} = this.img
          const maxSize = this.maxSize
          if(height > maxSize || width > maxSize){
            const landscape = width > height
            const ratio = landscape ? height / width : width / height
            this.canvas.width = landscape ? maxSize : maxSize * ratio
            this.canvas.height = landscape ? maxSize * ratio : maxSize
          } else {
            this.canvas.width = width
            this.canvas.height = height
          }
          pica.resize(this.img, this.canvas).then(() => {
            const selectedImg = this.canvas.toDataURL('image/'+extension)
            //this.preview.src = selectedImg
            this.submit(extension, selectedImg)
          }).catch(err => {
            this.cb({error: err})
            console.log(`Upload Error`, err)
          })
        }
        if(!this.maxSize){
          this.submit(extension, reader.result)
        } else {
          this.img.src = reader.result
        }
      }, false)

      if(file){
        extension = file.name.split('.').pop()
        reader.readAsDataURL(file)
      }
    }
  }

  render() {
    return <div className='image-processor' style={{display: 'none'}}>
      <div className='image'>
        <input ref={i => this.fileInput = i}
          type='file'
          onChange={this.readImage}
          onBlur={()=>this.cb({error: 'Upload canceled'})}
          accept='.png,.jpg,.jpeg,.svg'
        />
        <img style={{display: 'none'}} ref={img=>this.img = img} />
      </div>
    </div>
  }
}

export const getUpload = (cb, options) => {
  const iu = getGlobal("ImageUploaderComponent")
  if(!iu) {
    console.log('no uploader found, please include the <ImageUploader /> component')
    return
  }
  iu.upload(cb, options)
}

export const asyncUpload = (options) => {
  return new Promise((resolve, reject) => {
    getUpload(data => resolve(data.url), options)
  })
}

export const getBase64 = (options) => {
  return new Promise((resolve, reject) => {
    getUpload((data) => resolve(data), Object.assign({}, options, {skipUpload: true}))
  })
}
