import React from 'react'
import { getGlobal, setGlobal, loadGlobal, createDoc, Spinner,
  updateDoc, deleteDoc, Modal, Collapsible, apiPost, helpers } from 'launchpad'
import _ from 'lodash'
import styled from 'styled-components'


/***************************************************
  NOTIFICATIONS
****************************************************/

const Notifications = styled.div`
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 999999;
  ${'' /* pointer-events: none; */}
  max-height: calc(100vh - 30px);
  overflow: hidden;
  pointer-events: none;

  .notification {
    position: relative;
    width: 300px;
    right: -600px;
    transition: all .5s;
    overflow: hidden;

    &.active {
      right: 0px;
      .notification {
      }
    }
    .notification-wrapper {
      background: rgba(255,255,255,.95);
      border: 1px solid #eee;
      ${'' /* background: rgba(30, 210, 100, .9);
      border: 1px solid rgba(30, 210, 100, .9); */}
      color: #555;
      border-radius: 3px;
      box-shadow: 2px 2px 5px -1px rgba(0,0,0,.2);
      padding-left: 0px;
      width: 300px;
      margin-bottom: 10px;
      position: relative;
      .message {
        padding: 20px;
        padding-left: 50px;
      }
      .icon {
        position: absolute;
        top:0;
        left:0;
        width: 30px;
        font-size: 18px;
        height: 100%;

        opacity: .15;
        background: black;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &.success-type {
        background: rgba(30, 210, 100, .9);
        border: 1px solid rgba(30, 210, 100, .9);
        font-weight: bold;
        color: white;
      }
      &.warning-type {
        background: rgba(255, 230, 90, .9);
        border: 1px solid rgba(255, 230, 90, .9);
        font-weight: bold;
        color: black;
      }
      &.error-type {
        background: rgba(215, 50, 10, .9);
        border: 1px solid rgba(215, 50, 10, .9);
        color: white;
        font-weight: bold;
      }
    }
  }
`


let notificationCenter = null

export class NotificationContainer extends React.Component {
  state = {
    messages: [],
    activeMessages: []
  }

  componentDidMount() {
    notificationCenter = this
  }

  addNotification = (n) => {
    if(n) {
      this.setState({ messages: [ n ].concat(this.state.messages) })
      const delay = n.options.delay * 1000 || 0
      setTimeout(() => this.activateMessage(n), delay)
      const duration = (n.options.duration * 1000 || Math.max(n.message.length * 80, 5000)) + delay
      setTimeout(() => this.hideMessage(n), duration)
      setTimeout(() => this.removeMessage(n), duration + 200)
    }
  }

  activateMessage = (n) => {
    this.setState({ activeMessages: [ n.id ].concat(this.state.activeMessages) })
  }

  hideMessage = (n) => {
    this.setState({
      activeMessages: this.state.activeMessages.filter(id => id != n.id)
    })
  }

  removeMessage = (n) => {
    this.setState({
      messages: this.state.messages.filter(e => e.id != n.id)
    })
  }

  render() {
    return <Notifications className='notifications-container'>
      {this.state.messages.map(n => {
        const icons = { notification: 'info', warning: 'warning', error: 'ban', success: 'check' }
        const open = this.state.activeMessages.includes(n.id)
        return <Collapsible open={open} key={n.id} className={`notification` + (open ? ' active' : '')}>
          <div className={`notification-wrapper ${n.options.type}-type`} onClick={() => this.hideMessage(n)}>
            <div className='icon'><span className={`fa fa-${icons[n.options.type]}`} /></div>
            <div className='message'>{n.message}</div>
          </div>
        </Collapsible>
      })}
    </Notifications>
  }
}

let notifID = 0
let lastNotifications = []
let notifications = []
let notTimer = null
export const notify = (msg, options) => {
  clearTimeout(notTimer)
  if(!lastNotifications.includes(msg)) {
    lastNotifications.push(msg)
    notifID+=1
    if(notificationCenter) {
      notifications.push({ id: notifID, message: msg, options: Object.assign({ type: 'notification' }, options || {}) })
    }
    // prevent the same notification from being delivered more than once per second
    setTimeout(() => lastNotifications = lastNotifications.filter(x => x != msg), 1000)
  }
  notTimer = setTimeout(() => {
    notifications.forEach((n, idx) => {
      setTimeout(() => notificationCenter.addNotification(n), idx * 50)
    })
    notifications = []
  }, 100)
}
