/*

Basic (optional) functions to facilitate rapid development of CMS functionality

use `launchpadInit(<App />, '[root id]')` to make "global state" available e.g.:
setGlobal({xyz: 'value'});   -->   getGlobal('xyz');

include the `<AdminBar />` component anywhere in the app to make <Snip> editing
possible (with support for headings, b, i, etc.) and to use media library
functionality e.g.:  getMedia(img => doStuff(img));

*/

import React from 'react'
import ReactDOM from 'react-dom'
import openSocket from 'socket.io-client'


// for easy import, e.g. `import {Image} from 'launchpad';`
export { DataStore, ItemForm } from 'launchpad/admin/DataStore'
export { Image, ImageGetter } from 'launchpad/admin/widgets/Image'
export { Snip, getLorem, TextEditor } from 'launchpad/admin/widgets/snips'
export { Loading, Spinner } from 'launchpad/admin/widgets/Loading'
export { Modal, ModalContainer } from 'launchpad/admin/widgets/Modal.jsx'
export { Page, PageContext } from 'launchpad/admin/widgets/page'
export { Paginate } from 'launchpad/admin/widgets/Paginate'
export { Slider } from 'launchpad/admin/widgets/Slider'
export { Setting } from 'launchpad/admin/widgets/Setting'
export { pageList, pageIds, pageMap, pageProps,
  pageLinks, pageComponents } from 'launchpad/devops/_generated/page-index'

export { AdminPanel } from 'launchpad/admin/AdminPanel'
export { AdminLogin } from 'launchpad/admin/AdminLogin'
export { Input } from 'launchpad/admin/widgets/Input'

// TODO: keeping this as a proxy, should clean up eventually
export { Collapsible } from 'widgets'

export { Meta, MetaEditor } from 'launchpad/admin/widgets/MetaEditor'
export { DynamicMenu } from 'launchpad/admin/widgets/DynamicMenu/DynamicMenu'
export {
  DraggableLink
} from 'launchpad/admin/widgets/DynamicMenu/DraggableLink'
export { confirm, notify } from './helpers'
import { notify, startLoad, stopLoad } from './helpers'
export { qs, setQS } from './helpers/qs'
export { default as helpers } from 'launchpad/devops/helpers'

import { globalState, setGlobal, getGlobal } from 'launchpad/globals'
export { setGlobal, getGlobal } from 'launchpad/globals'

/* basic API interaction
========================================= */

//TODO: API_BASE is more or less irrelevant now that we're using webpack to proxy to
// the API (mimics production), may be worth revisiting the logic here

//const API_BASE = 'http://localhost:5007';
const API_BASE = ''

export const notifyDisconnect = err => {
  console.error(`
===============================
  ⚠️ API CONNECTION ERROR ⚠️
===============================

${err.stack}
  `)
  notify(
    'Sorry, something seems to have gone wrong! If this message keeps appearing, please try refreshing the page.',
    { type: 'error' }
  )
}



/*
  launchpad overview: api interaction

  Obviously you can just use fetch (or whatever method you prefer), but the standard
  builtin CMS interaction is wired through the `apiRequest` function with automatic
  prefixing. These functions assume your api can read JSON formatted data and expect
  JSON data in return which is automatically parsed.

  That means in practice you can send things like `{data: 'information'}` and expect
  a response like `{success: true}` (no stringify or parsing needed).
*/

/*
  launchpad function: apiRequest
  categories: api interaction, core
  Generic api request/response.  Requests are automatically prefixed with `/api/v1`,
  the standard express based API is already built to mirror that approach, but when
  integrating with an existing api you'll either need to remove the prefix here
  or add it to the routes you create

  arguments:
    route - (String) the route you would like to access (without `/api/v1` prefix)
    data - (Object) an object which will be converted to JSON and passed to the API
    method - (String) http method, e.g. `GET` or `POST`

  return:
    <Promise> whatever the API returns in response to the given request (parsed)
*/
export function apiRequest(route, data, method) {
  if (route.startsWith('/')) route = route.slice(1)
  return new Promise((resolve, reject) => {
    fetch(`${API_BASE}/api/v1/${route}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      method: method,
      body: JSON.stringify(data)
    })
      .then(resp => {
        if (!resp || resp.status != 200) return reject(resp)
        if (!getGlobal('apiConnected')) setGlobal({ apiConnected: true })
        const contentType = resp.headers.get('content-type')
        if(contentType && contentType.includes("application/json")) {
          try {
            resolve(resp.json().catch(e => reject(e)))
          } catch (e) {
            resolve(resp)
          }
        } else {
          resolve(resp.text())
        }
      })
      .catch(err => {
        notifyDisconnect(err)
        reject(err)
      })
  })
}

/*
  launchpad function: apiGet
  categories: api interaction, core

  GET request from api (see apiRequest)

  arguments:
    route - (String) the url you would like to send a GET request to
  return:
    <Promise> parsed response from API
 */
export function apiGet(route, data) {
  if (route.startsWith('/')) route = route.slice(1)
  if(data) {
    const queries = Object.keys(data).map(key => `${key}=${data[key]}`)
    route = `${route}?${queries.join('&')}`
  }
  return new Promise((resolve, reject) => {
    fetch(`${API_BASE}/api/v1/${route}`, {
      method: 'get',
      credentials: 'include'
    })
      .then(resp => {
        if(!resp || resp.status != 200) {
          reject(resp)
        }
        resolve(resp.json().catch(err => reject(err)))
      })
      .catch(err => notifyDisconnect(err))
  })
}

/*
  launchpad function: apiPost
  categories: api interaction, core

  POST request to API (see apiRequest)

  arguments:
    route - (String) the url you would like to send a POST request to
    data - (Object) data you wish to pass to the API
  return:
    <Promise> parsed response from API
 */
export function apiPost(route, data) {
  return apiRequest(route, data, 'POST')
}

// post given data to given API route
export function apiPut(route, data) {
  return apiRequest(route, data, 'PUT')
}

export function apiUpload(route, data) {
  if (route.startsWith('/')) route = route.slice(1)
  return fetch(`${API_BASE}/api/v1/${route}`, {
    method: 'POST',
    body: data
  }).then(resp => resp.json())
}

/* basic auth functionality
============================================== */

// login
export function login(email, password) {
  return apiPost('admin/login', { email, password })
}

// logout
export function logout() {
  apiPost('admin/logout')
    .then(checkAuth)
    .catch(e => console.log(e))
}

// check auth status
// load collection from API into global state
export function checkAuth(cb) {
  apiGet('auth-status')
    .then(data => {
      if (data.admin || data.user_access == 'admin') {
        setGlobal({ is_admin: data.admin, user_access: data.user_access })
      } else {
        setGlobal({ is_admin: false, user_access: null })
      }
      if (typeof cb == 'function') {
        cb(data.admin)
      }
    })
    .catch(error => {
      console.log(`error checking auth status`, error)
      setGlobal({ is_admin: false })
      //TODO: error handling
    })
}

/* Launchpad tools, e.g. Media Library
===================================================== */

// runs functions if launchpad_data.AdminBar exists, else logs error
function runAdmin(func, name) {
  if (globalState && globalState.AdminBar) {
    func()
  } else {
    console.error(
      (name ? name : func.name) +
        '() requires the <AdminBar> component to be mounted'
    )
  }
}

// aliases for common media related tasks
export function getMedia(cb, current) {
  runAdmin(() => {
    globalState.MediaLibrary.getFile(cb, current)
  }, 'getMedia')
}

export function showMedia() {
  runAdmin(() => globalState.MediaLibrary.show(), 'showMedia')
}

export function hideMedia() {
  runAdmin(() => globalState.MediaLibrary.hide(), 'hideMedia')
}

export function toggleMedia() {
  runAdmin(() => {
    let ml = globalState.MediaLibrary
    if (ml.state.open) {
      ml.hide()
    } else {
      ml.show()
    }
  })
}

let appHistory

export function getHistory() {
  return appHistory
}

/*
  launchpad function: launchpadInit
  categories: core
  use this function to initialize the app, it preloads most of the important
  data from the API. Typically the default usage in /src/index.jsx won't need
  to be adjusted.

  arguments:
    app - (Component) e.g. `<App />`
    id - (String) the id of the DOM element where the app should be rendered
*/
export function launchpadInit(app, id, options) {
  // begin loading images, then load text before rendering the app
  if (options) {
    appHistory = options.history
  }
  reloadSnips(() => {
    ReactDOM.render(app, document.getElementById(id))
  })
  reloadImageInstances()
  reloadSettings()
  loadGlobal('meta')
}

/* API related functions
------------------------------------------------------*/

let loadHooks = {}

// register reload hook
export function addLoadListener(col, id, fn) {
  if (!loadHooks[col]) {
    loadHooks[col] = []
  }
  loadHooks[col][id] = fn
}

// remove load hook
export function removeLoadListener(col, id) {
  delete loadHooks[col][id]
}

// load collection from API into global state
export function loadGlobal(collection, cb) {
  apiGet(collection)
    .then(data => {
      //console.log(collection + ' loaded')
      setGlobal({ [collection]: data, [`${collection}_loaded`]: true, noAPIConnection: false })
      if (typeof cb === 'function') cb()
      for (let x in loadHooks[collection]) {
        loadHooks[collection][x]()
      }
    })
    .catch(error => {
      console.error(`error loading ${collection}:`, error)
      setGlobal({ noAPIConnection: true })
      //TODO: error handling
    })
    .finally(() => {
      if (typeof cb === 'function') cb()
    })
}

// loads all snips and refreshes app
export function reloadSnips(cb) {
  loadGlobal('snips', cb)
}

// loads all images and refreshes app
export function reloadImageInstances(cb) {
  loadGlobal('images', cb)
}

// loads all media references (not the actual files)
export function reloadImages(cb) {
  loadGlobal('media', cb)
}

// loads all settings
export function reloadSettings(cb) {
  loadGlobal('settings', cb)
}

/* CRUD API interactions
-----------------------------------------*/

// create new document in given collection
export function createDoc(collection, data) {
  return apiPut(collection, data)
}

// delete existing document
export function deleteDoc(collection, id) {
  return apiRequest(`${collection}/${id}`, {}, 'delete')
}

// update existing document without debounce
export function updateDoc(collection, data) {
  return apiRequest(`${collection}`, data, 'post')
}

let updateDebounceTimer = null
let updated = {}

// debounced updates (consolidates rapid updates to the same object to one request)
export function debouncedUpdateDoc(collection, key, atts, options) {
  if (!updated[collection]) {
    updated[collection] = {}
  }
  let item = updated[collection][key]
  if (typeof item === 'undefined' && getGlobal(collection)) {
    item = getGlobal(collection)[key]
  }
  if (!item) {
    item = {}
  }
  for (let key in atts) {
    item[key] = atts[key]
  }
  updated[collection][key] = item

  if (updateDebounceTimer) {
    clearTimeout(updateDebounceTimer)
  }
  updateDebounceTimer = setTimeout(() => {
    // push all updates
    for (let col in updated) {
      for (let key in updated[col]) {
        updateDoc(col, updated[col][key]).then(res => {
          loadGlobal(col)
          if (options && options.cb) options.cb()
        })
        delete updated[col][key]
      }
    }
  }, 300)
}

// upload file and create reference in media
export function uploadMedia(file, progressCB, doneCB) {
  let uploader = new XMLHttpRequest()
  uploader.upload.addEventListener(
    'progress',
    e => {
      let percent = Math.round(e.loaded / e.total * 100)
      progressCB(percent)
    },
    false
  )
  uploader.upload.addEventListener('load', () => {
    if (typeof doneCB === 'function') {
      doneCB(uploader.responseText)
    }
    reloadImages()
  })
  uploader.open('POST', `${API_BASE}/api/v1/upload-media`, true)
  uploader.setRequestHeader('Content-type', file.type)
  uploader.send(file)
}

// delete file from storage and media references by url
export function removeMedia(url) {
  let mediaRef = getGlobal('media').find(m => m.url == url)
  deleteDoc('media', mediaRef._id).then(() => {
    loadGlobal('media')
  })
}

// get and set snips
export function setSnip(value, name, page) {
  let newSnip = getSnip(name, page)
  newSnip.draft = value
  let updated = false
  let snips = getGlobal('snips').map(snip => {
    if (snip.name == name && snip.page == page) {
      updated = true
      return newSnip
    }
    return snip
  })
  if (!updated) snips.push(newSnip)
  setGlobal({ snips })
  updateDoc('snips', newSnip)
}

export function getSnip(name, page) {
  page = page || 'global-text'
  let snip = (getGlobal('snips') || []).find(
    x => x.page == page && x.name == name
  )
  return snip ? snip : { content: '', page, name }
}

export function getSnips(page) {
  return (getGlobal('snips') || []).filter(
    x => x.groups && x.groups.includes(page)
  )
}

export const getDesyncedPages = () => {
  const snips = getGlobal('snips') || []
  let desynced = []
  for (let s of Array.from(snips.filter(x => !!x.name))) {
    if (s.draft && s.content != s.draft && !desynced.includes(s.page)) {
      desynced.push(s.page)
    }
  }
  return desynced
}

// TODO: segmented publishing (e.g. by page, or by snip)
export const publishPageUpdates = async () => {
  const l = startLoad()
  await Promise.all(
    getDesyncedPages().map(async page => {
      return await apiPost(`publish-page/${page}`)
    })
  )
  reloadSnips()
  stopLoad(l)
  notify('Changes successfully published!!', { type: 'success' })
}

// get and set page meta
export function setMeta(page, name, value) {
  let meta = getMeta(page, name)
  meta.value = value
  debouncedUpdateDoc('meta', page + name, meta)
}

export function getMeta(page, name) {
  let meta = (getGlobal('meta') || []).find(
    x => x.page == page && x.name == name
  )
  return meta ? meta : { page, name }
}

export function applyMeta() {
  const config = require('config').default
  if (document) {
    // TODO: expose ability to set default title/description
    let title =
      getMeta(getGlobal('pageContext'), 'title').value || config.project_title
    let description =
      getMeta(getGlobal('pageContext'), 'description').value ||
      config.project_title
    document.querySelector('title').innerHTML = title == config.project_title ? title : 'PetProducts | ' + title
    document.querySelector('meta[name=description]').content = description
    if (getGlobal('app')) getGlobal('app').forceUpdate()
  }
}

addLoadListener('meta', 'main', applyMeta)

// get and set images
export function setImage(group, label, obj) {
  let image = getImage(label, group)
  if (image._id) {
    for (let key in obj) {
      image[key] = obj[key]
    }
    saveImage(group, label, obj)
  } else {
    saveImage(group, label, obj).then(reloadImageInstances)
  }
}

function saveImage(page, name, obj) {
  obj = Object.assign({ page, name }, obj)
  let image = getImage(name, page)
  if (image._id) {
    obj._id = image._id
    debouncedUpdateDoc('images', image._id, obj)
  } else {
    return createDoc('images', Object.assign({ page, name }, obj))
  }
}

export function getImage(name, page) {
  let url, small

  const image = (getGlobal('images') || []).find(
    x => x.name == name && x.page == page && x._id != 'temp'
  )
  if (image) {
    let media = (getGlobal('media') || []).find(x => x._id == image.mediaId)
    if (media) {
      url = media.url
      small = media.small || media.url
    }
    if(!url) url = image.url
  }
  return Object.assign({}, image || {}, { url, small })
}

export function setSetting(obj, cb) {
  let update = {}
  for (let key in obj) {
    update.name = key
    update.value = obj[key]
    //console.log(`setting ${key} to:`, obj[key])
    if (typeof update.value == 'object') {
      update.value = JSON.stringify({ launchpad_encoded_object: update.value })
    }
  }
  let setting = (getGlobal('settings') || []).find(x => x.name == update.name)
  if (setting) {
    update._id = setting._id
  }
  if (setting && setting._id) {
    debouncedUpdateDoc('settings', setting._id, update, { cb: cb })

    //immediately update local copy of settings
    setGlobal({
      settings: getGlobal('settings').map(s => {
        if (setting._id == s._id) {
          s = Object.assign({}, setting, update)
        }
        return s
      })
    })
  } else {
    createDoc('settings', update).then(reloadSettings)
  }
}

export function getSetting(name) {
  let setting = (getGlobal('settings') || []).find(x => x.name == name)
  if (
    setting &&
    setting.value &&
    setting.value.includes &&
    setting.value.includes('launchpad_encoded_object')
  ) {
    setting.value = JSON.parse(setting.value).launchpad_encoded_object
  }
  return setting ? setting.value : ''
}

/* socket.io server interaction
========================================= */
let socket = null

export const getSocket = () => {
  if (!socket) socket = openSocket(
    `${window.location.origin}`.replace('http://', 'ws://').replace('https://', 'wss://'),
    { secure: window.location.protocol == 'https:' }
  )
  return socket
}

export function getUrlVars() {
  var vars = {}
  var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(
    m,
    key,
    value
  ) {
    vars[key] = value
  })
  return vars
}
