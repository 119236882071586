import React from 'react'
import { Page, Snip, Collapsible, getGlobal, setGlobal, apiPost, apiGet,
  notify, Spinner } from 'launchpad'
import { Slideshow } from 'widgets'
import { load, endLoad } from './tools'
import { useSetState } from 'launchpad/helpers'

export const loadUser = async (options) => {
  options = options || {}
  if(!getGlobal('user') || options.refresh) {
    const u = await apiGet('user-status')
    if(u) {
      setActiveUser(u.user)
      setGlobal({ company_access_level: u.company_access_level })
      setGlobal({ necessary_agreements: u.necessary_agreements })
    }
  }
  return getGlobal('user')
}

export const updateUser = async update => {
  const { user } = getGlobal()
  await apiPost('update-user/'+user.id, update)
}

export const login = async (email, password) => {
  load('login')
  try {
    const u = await apiPost('login', { email, password })
    if(u && u.user) {
      await setActiveUser(u.user)
    } else {
      notify('Sorry, that email or password is incorrect', { type: 'error' })
    }
  } catch (e) {
    notify(`Sorry, it looks like we're unable log you in at the moment, please try again in a few minutes`, { type: 'error' })
  } finally {
    endLoad('login')
  }
  return getGlobal('user')
}

export const logout = async () => {
  load('logout')
  await apiPost('logout')
  endLoad('logout')
  window.location.reload()
}

export const setActiveUser = async (user) => {
  if(user) {
    user.id = user._id.$oid
    setGlobal({ user })
    await loadUser()
  }
}

export function Login () {
  const [ state, setState ] = useSetState({
    email: '',
    password: '',
    confirmPassword: '',
    slide: 2,
    company: '',
    position: '',
    name: '',
    loggingIn: false,
    registering: false,
    resettingPassword: false
  })

  const { email, password, slide, company, position, name, confirmPassword } = state

  React.useEffect(() => {
    if(window.location.hash == '#register') {
      setState({ slide: 3 })
    }
  }, [])

  const doLogin = async (e) => {
    setState({ loggingIn: true })
    e.preventDefault()
    if(email && password) {
      await login(email, password)
    } else {
      notify(`Please enter a valid email address and password`)
    }
    setState({ loggingIn: false })
  }

  const doRegister = async (e) => {
    e.preventDefault()
    setState({ registering: true })
    let message = ''
    if(!email) message += '\nPlease enter a valid email address.'
    if(!name) message += '\nPlease enter your name.'
    if(password != confirmPassword) message += '\nPasswords do not match!'
    if(message){
      notify(message)
    } else {
      const data = await apiPost('/registration', { email, name, company, position, password })
      if(data.error) {
        notify(data.error, { type: 'warning' })
      } else {
        await login(email, password)
      }
    }
    setState({ registering: false })
  }

  const doForgot = async (e) => {
    e.preventDefault()
    setState({ resettingPassword: true })
    const { error } = await apiPost('/start_reset_password', { email })
    if(error) {
      notify(error, { type: 'warning' })
    } else {
      notify(`Please check your email for further instructions`)
    }
    setState({ resettingPassword: false })
  }

  return <div className='login'>
    <Slideshow slide={slide}>
      <form onSubmit={doForgot} className='login-form'>
        <input value={email} onChange={e => setState({ email: e.target.value })} type='email' placeholder='email' />
        <button>{!state.resettingPassword ? 'Reset Password' : <Spinner />} </button>
        <p>Know your password? <a href='#' onClick={() => setState({ slide: 2 })}>Click here to login.</a></p>
      </form>

      <form onSubmit={doLogin} className='login-form'>
        <input value={email} onChange={e => setState({ email: e.target.value })} type='email' placeholder='email' />
        <input value={password} onChange={e => setState({ password: e.target.value })} type='password' placeholder='password' />
        <button>{!state.loggingIn ? 'Login' : <Spinner />} </button>
        <p>Don't have an account? <a href='#register' onClick={() => setState({ slide: 3 })}>Click here to register.</a></p>
        <p>Forgot your password? <a href='#forgot-password' onClick={() => setState({ slide: 1 })}>Click here to reset it.</a></p>
      </form>

      <form onSubmit={doRegister} className='login-form'>
        <input value={email} name='email' onChange={e => setState({ email: e.target.value })} type='email' placeholder='email' />
        <input value={name} onChange={e => setState({ name: e.target.value })} placeholder='Name' />
        <input value={company} onChange={e => setState({ company: e.target.value })} placeholder='Company' />
        <input value={position} onChange={e => setState({ position: e.target.value })} placeholder='Position' />
        <input value={password} onChange={e => setState({ password: e.target.value })} type='password' placeholder='Password' />
        <input value={confirmPassword} onChange={e => setState({ confirmPassword: e.target.value })} type='password' placeholder='Confirm Password' />
        <button>{!state.registering ? 'Register' : <Spinner />} </button>
        <p>Already have an account? <a href='#' onClick={() => setState({ slide: 2 })}>Click here to log in.</a></p>
      </form>
    </Slideshow>
  </div>
}
