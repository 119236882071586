/*
  launchpad overview: sections
  Sections are components representing blocks/rows of content, like a text/image row or a CTA.
  Many of these components have builtin editable zones and configuration options.
  Sections can typically be imported via:

  ```
    import {Section} from 'sections'
  ```

*/

import React from 'react'
export { ContentBlock } from './ContentBlock'
export { CTA } from './CTA'
export { Table } from './Table'
export { OptionsPanel, LayoutSelector, Section } from './options'
export { default as Hero } from './partial-hero/Hero';
