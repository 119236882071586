import React, { lazy, Suspense, useState, useEffect } from 'react'
import { setGlobal, getGlobal, apiGet, helpers,
  pageProps, pageComponents } from 'launchpad'
import { getSlug, useSetState } from 'launchpad/helpers';
import { _ } from 'underscore'
import { refreshFunctions, customRoutes } from 'config/routes'


// used as a container for routes passed in via register functions
export let additionalRoutes = {}

// modifiable copy of custom_routes
export let route_map = {}


// get routes generated by `npm run crawl` (see launchpad/devops/crawl.js)
let autoRoutes = {}
try {
  const global_config = require('../../global_config').default
  const routeList = require('launchpad/devops/_generated/valid-routes').default
  const delimiter = (global_config.existing_url || '').replace('http://', '').replace('https://', '')
  routeList.forEach(route => {
    route = route.split(delimiter)[1];
    if(route && route != '/' && !route.includes(['"', '&', '?', '#'])){

// NOTE: uncomment this line to include stubbed routes from the crawler
      //autoRoutes[route] = Stub

    }
  })
} catch(e) {/* assume auto-routes doesn't exist */}



// tree will be kept here once calculated for faster rendering
let cachedTree = null


// functions to efficiently get subsets of routing data
export const getCachedTree = (options) => {
  if(cachedTree) return cachedTree.tree
  return getRouteTree().tree
}

export const getCachedTitles = (options) => {
  if(cachedTree) return cachedTree.titleMap
  return getRouteTree().titleMap
}

export const getCachedRouteInfo = (options) => {
  if(cachedTree) return cachedTree.infoMap
  return getRouteTree().infoMap
}

export const getCachedMap = (options) => {
  if(cachedTree) return cachedTree.route_map
  return getRouteTree().route_map
}



// calculate url structure based on route data
export const getRouteTree = (options) => {
  options = options || {}

  // return cached tree if it exists unless a hard refresh is requested
  if(cachedTree && !options.hardRefresh) return cachedTree

  // reset route map
  route_map = {}

  // add routes from the page index
  Object.keys(pageProps).forEach(key => {
    const p = pageProps[key]
    if(p.id){
      let route = Object.assign(p, {
        component: key,
        title: p.launchpad_title || helpers.getTitle(p.id), id: p.id,
        type: p.launchpad_type || 'main'
      })
      route_map[route.url] = route
    }
  })

  // combine indexed routes with autoRoutes (from crawler), overriding auto-routes with route_map
  route_map = Object.assign({}, autoRoutes, route_map, additionalRoutes)

  let titleMap = {}
  let infoMap = {}

  // returns a link object usable by page index and menus
  const linkObj = (base, url, type) => {
    let obj = {
      title: (route_map[url] && route_map[url].title) || helpers.getTitle(base),
      id: (route_map[url] && route_map[url].id),
      base: base,
      types: [],
      children: [],
      links: []
    }
    if(url){
      obj.url = url
    }
    if(type){
      obj.types = [type]
    }

    obj.addChild = function(child) {
      const excluded = ['/home', '/']
      // short circuit, don't add home page to any tree
      if(excluded.includes(child.url)) return

      this.pushLink(child.url)
      child.types.forEach(t => {
        if(!this.types.includes(t)) this.types.push(t)
      })

      child.parent = this
      titleMap[child.url] = child.title
      if(child.id){
        infoMap[child.id] = {
          url: child.url,
          title: child.title,
          data: route_map[child.url]
        }
      }
      this.children.push(child)
    }

    obj.pushLink = function(link) {
      if(link && !this.links.find(l => l == link)) this.links.push(link)
      if(this.parent) this.parent.pushLink(link)
    }

    return obj
  }

  let route_tree = linkObj('/', '/')


  // adds dissected route to tree (recursive, unpacks children)
  const addToTree = (parts, url, parent, type) => {
    //if(parent) console.log('parent', parent)
    if(!parent) parent = route_tree

    if(parts.length < 2) {
      let link = parent.children.find(link => link.base == parts[0])
      if(link){
        link.isPage = true
      } else {
        parent.addChild(linkObj(parts[0], url, type))
      }
    } else if(parts && parts.length) {
      let newParent = parent.children.find(link => link.base == parts[0])
      if(!newParent){
        newParent = linkObj(parts[0], null, type)
        parent.addChild(newParent, url)
      }
      addToTree(parts.slice(1), url, newParent, type)
      //addToTree(parts, url)
    }
  }

  // take each route and feed it through the pipeline to generate a tree
  Object.keys(route_map).forEach(key => {
    let url = key
    const link = route_map[key]

    let type = link.type || 'main'

    const parts = url.split('/').filter(part => part)
    if(parts.length && !parts[parts.length - 1].startsWith(":")){
      addToTree(parts, url, null, type)
    }
  })

  // recursively sort the tree alphabetically and add home page to top level
  const sortTree = (tree) => {

    if(tree.children){
      tree.children = _.sortBy(tree.children, 'url')
    }
    tree.children = tree.children.map(tree => sortTree(tree))
    if(tree.base == '/') {
      tree.children = [{base: 'Home', url: '/', children: []}].concat(tree.children)
    }
    return tree
  }

  //setGlobal({ titleMap })
  const completeTree = {
    tree: sortTree(route_tree),
    titleMap,
    infoMap,
    route_map
  }

  cachedTree = completeTree
  return completeTree
}




export const getValidRoutes = () => {
  return getGlobal('AutoRoutes') && getGlobal('AutoRoutes').state.validUrls
}

// TODO: maybe implement this for better route refreshing
let routeListeners = []
export const registerRouteListener = (l) => routeListeners.push(l)
export const unregisterRouteListener = (l) => routeListeners = routeListeners.filter(x => x != l)


const getRouteList = () => {
  let routes = `"Name", "Url"
`
  Object.keys(route_map).forEach(x => routes += `"${route_map[x].title}", "${x}"\n`)
  return routes
}

const getRouteCSV = () => {
  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(getRouteList()));
  element.setAttribute('download', 'routes.csv');
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

window.getRouteCSV = getRouteCSV

export let validUrls = []
export let routesLoaded = false


export const refreshRoutes = async () => {
  // reset additional routes
  additionalRoutes = {}
  await Promise.all(refreshFunctions.map(fn => {
    return (async () => {
      const routes = await fn()
      additionalRoutes = { ...additionalRoutes, ...routes }
    })()
  }))
  routesLoaded = true
  setGlobal({ routeTree: getRouteTree({hardRefresh: true}) })
}
