const routes = [
  "https://www.meraki-go.com/c/r/merakigo/en/us/",
  "//www.meraki-go.com/c/r/merakigo/en/us/buy.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/support/index.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/index.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/products/index.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/training-events.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/solutions/index.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/partners.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/solutions/small-business.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/solutions/midsize/overview.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/solutions/service-provider/index.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/solutions/industries.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/solutions/industries/automotive.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/solutions/industries/consumer-packaged-goods.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/solutions/industries/energy.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/solutions/industries/education.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/solutions/industries/government.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/solutions/industries/healthcare.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/solutions/industries/financial-services.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/solutions/industries/hospitality.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/solutions/industries/manufacturing.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/solutions/industries/materials-mining.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/solutions/industries/public-sector.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/solutions/industries/retail.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/solutions/industries/smart-connected-communities.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/solutions/industries/sports-entertainment.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/solutions/partner-ecosystem.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/solutions/industries/transportation.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/support/web/tsd-products-field-notice-summary.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/solutions/technology-trends.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/solutions/cloud/overview.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/solutions/internet-of-things/overview.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/about.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/about/csr/impact/environmental-sustainability.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/about/csr.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/solutions/software-defined-networking/overview.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/about/trust-center.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/about/careers.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/about/careers/we-are-cisco.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/support/web/communities/vip.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/solutions/cisco-powered/overview.html",
  "//www.meraki-go.com/c/r/merakigo/en/us/buy/cisco-capital-finance/overview.html"
]

module.exports.default = routes
