import React from 'react'
import {apiGet, helpers, Loading} from 'launchpad'
import {Collapsible} from 'widgets'
import styled from 'styled-components';

const Container = styled.div`
  .muted { color: #bbb; }

  .pagination-tools {
    background: #efefef;
    border-radius: 3px 3px 0 0;
    color: #555;
    .search {
      flex: 1;
      padding: 5px;
      display: flex;
      input {
        flex: 1;
        padding: 7px 15px;
        border-radius: 3px;
        background: #fff;
        border: 0;
      }
    }

    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      padding-top: 0;
    }
  }

  .results {
    width: 100%;
    position: relative;
    margin: 20px 0;
    tbody {
      tr {
        &:first-child {
          background: rgba(0,0,0,.03);
        }

        &:nth-child(2n) {
          background: rgba(80, 150, 205, .1);
        }

        th, td {
          flex: 1;
          padding: 10px;
          text-align: center;
          &:nth-child(2n) {
            background: rgba(0,0,0,.01)
          }
          &:not(:last-child){
            border-right: 1px solid rgba(0,0,0,.03);
          }
        }
      }
    }

  }

  .results-wrapper, .results-container {
    width: 100%;
    position: relative;
  }

  h3.muted {
    color: #ccc;
    padding: 40px;
    text-align: center;
  }

  .pages {
    display: flex;
    justify-content: center;
    background: #efefef;
    max-width: 100%;
    .page, .dots, .prev, .next {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid transparent;
      &:hover {
        background: #ccc;
      }
    }

    .page-container {
      white-space: nowrap;
      overflow: hidden;
      position: relative;
      div {
        display: inline-flex;
        flex: 1;
      }
      .inner {
        position: relative;
        transition: left .3s;
      }
    }

    .prev, .next {
      width: auto;
      padding: 0px 15px;
      opacity: .3;
      &.active {
        opacity: 1
      }
    }
    .page.active {
      background: rgb(0, 83, 215);
      color: white;
    }
    > div {
      cursor: pointer;
    }
  }

  .tools {
    button {
      margin-right: 10px;
      background: transparent;
    }
  }
`

export class Paginate extends React.Component {
  state = {
    page: 1,
    total: 0,
    perPage: 10,
    pages: 1,
    results: [],
    query: '',
    loading: true
  }

  componentDidMount() {
    this.setPage(1)
  }

  componentDidUpdate() {
    const {page, pages} = this.state
    if(page > pages && page != 1){
      this.setPage(pages > 0 ? pages : 1)
    }
  }

  get url() {
    const {page, perPage, query} = this.state
    const {filters, sort} = this.props
    const f = filters ? Object.keys(filters).map(k => {
      const val = filters[k]
      return `&${k}=${Array.isArray(val) ? val.join('|') : val}`
    }).join('') : ''
    const sortKey = sort ? Object.keys(sort)[0] : ''
    const s = sort ? `&sort=${sortKey}&sortDir=${sort[sortKey]}` : ''
    return `${this.props.collection}/page/${page}?perPage=${perPage}&query=${query}${f}${s}`
  }

  get showing() {
    const first = this.state.perPage * (this.state.page - 1)
    return `${first + 1} - ${first + this.state.results.length}`
  }

  setPage = (page) => {
    //console.log(`setting page ${page}`)
    const p = parseInt(page)
    if(this.state.page != p && this.props.onPageChange) this.props.onPageChange(p)
    const updateTime = Date.now()
    this.updated = updateTime
    this.setState({loading: true, page: parseInt(page)}, () => {
      if(this.props.getResults) {
        this.setState(Object.assign(this.props.getResults(this.state), {loading: false}))
      } else {
        apiGet(this.url).then(data => {
          if(this.updated == updateTime){
            this.setState({loading: false})
            if(data.error){
              console.error(data.error)
            } else {
              this.setState(data)
            }
          }
        })
      }
    })
  }

  reload = () => {
    this.setPage(this.state.page)
  }

  next = () => {
    const {page, pages} = this.state
    if(page < pages) this.setPage(this.state.page + 1)
  }

  prev = () => {
    if(this.state.page > 1) this.setPage(this.state.page-1)
  }

  update = (state) => {
    const page = state.query ? 1 : this.state.page
    if(state.query){
      clearTimeout(this.queryWait)
      this.setState(state, () => {
        this.queryWait = setTimeout(() => this.setPage(page), 200)
      })
    } else {
      this.setState(state, () => this.setPage(page))
    }

  }

  pageBars = {}
  pageInners = {}

  getPagination = (id) => {

    // list of numbers for pages
    const {total, page, perPage, results, pages} = this.state
    const prevActive = (this.state.page > 1)
    const nextActive = (page < pages)
    const preDots = page > 5
    const postDots = page < pages - 4
    const pageBase = Math.min(page - 4 > 1 ? page - 4 : 1, pages - 9 > 0 ? pages - 8 : 1)
    const pageArray = [...Array(pages).keys()].map(i=>i+1)
    //console.log(this.pageBars, this.activeNumber)
    const width = this.pageBars[id] && this.pageBars[id].offsetWidth
    const parentLeft = this.pageBars[id] && this.pageBars[id].offsetLeft
    const barwidth = this.pageInners[id] && this.pageInners[id].offsetWidth
    const innerLeft = this.pageInners[id] && this.pageInners[id].offsetLeft
    const left = this.activeNumber && this.activeNumber.offsetLeft

    let offset = left - (width / 2) + 20
    offset = Math.min(offset, barwidth - width)
    offset = Math.min(-offset, 0)


    //console.log(width, barwidth, left)
    return <Collapsible open={pages > 1} className='pages'>
      <div onClick={this.prev} className={`prev ${prevActive ? 'active' : ''} fa fa-chevron-left`} />
      <div className='page-container' ref={el => this.pageBars[id] = el}>
        <div className='inner' style={{left: `${offset}px`}} ref={el => this.pageInners[id] = el}>
          {pageArray.map(i =>
            <div key={i}
              ref={el => {if(page == i) this.activeNumber = el}}
              onClick={()=>this.setPage(i)}
              className={`page ${page==i ? 'active': ''}`}
              >
              {i}
            </div>)
          }
        </div>
      </div>
      <div onClick={this.next} className={`next fa fa-chevron-right ${nextActive ? 'active' : ''}`} />

      {/* {preDots && <div onClick={()=>this.setPage(1)} className={`page ${page==1 ? 'active': ''}`}>{1}</div> }
      {preDots && <span className='dots'>...</span>}

      {postDots && <span className='dots'>...</span>}
      {postDots && <div onClick={()=>this.setPage(pages)} className={`page ${page==pages ? 'active': ''}`}>{pages}</div> } */}

    </Collapsible>
  }

  renderResults = (items) => {
    if(this.props.renderResults) return this.props.renderResults(items)
    return <div className='results'>
      <table className='results-container'>
        <tbody>
          {items.length
            ? <tr>
                {Object.keys(this.props.columns({})).filter(key => key != 'className').map(key => <th key={key}>{helpers.getTitle(key)}</th>)}
              </tr>
            : <tr />
          }
          {items.length
            ? items.map(r => {
              let columns = this.props.columns(r)
              const cn = columns.className
              delete columns.className
              return <tr key={r._id} className={`result ${cn}`}>
                {Object.keys(columns).map(col => <td key={col}>{columns[col]}</td>)}
              </tr>
            })
            : <tr />
          }
        </tbody>
      </table>
    </div>
  }

  render() {
    const {total, page, perPage, results, pages, loading} = this.state
    const { hideTools, paginatePosition } = this.props
    const items = (results || [])

    return <Container>
      {!hideTools && <div className='pagination-tools'>
        <div className='search'>
          <input value={this.state.query} onChange={e => this.update({query: e.target.value})} placeholder={`Search ${helpers.getTitle(this.props.collection)}`} />
        </div>
        <div className='info'>
          <div className='status'>{this.state.matches ? `Showing ${this.showing} of ${this.state.matches}` : 'Showing 0 - 0 of 0'}</div>
          <div>
            <select onChange={e=>this.update({perPage: parseInt(e.target.value)})} value={perPage}>
              {[10, 20, 50, 100].map(i => <option key={i}>{i}</option>)}
            </select> items per page
          </div>
        </div>
      </div> }
      { paginatePosition != 'bottom' && this.getPagination('top') }
      <div className='results-wrapper'>
        { !items.length && <h3 className='muted'>No results found</h3> }
        { this.renderResults(items) }
        <Loading active={this.state.loading} />
      </div>
      { paginatePosition != 'top' && this.getPagination('bottom') }
    </Container>
  }
}
