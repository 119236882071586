import React, { useEffect, useState } from 'react'
import { setGlobal, getGlobal } from 'launchpad'
import { useSetState } from 'launchpad/helpers'
import { Link, Collapsible, DropDown } from 'widgets'
import { ScrollTrigger } from 'react-move-stuff'


const HeaderLink = (props) => {
  let p = { ...props }
  const active = p.href == window.location.pathname + window.location.hash

  useEffect(() => {
    if(active) {
      setGlobal({ activeLink: p.href })
    } else if(getGlobal('activeLink') == p.href) {
      setGlobal({ activeLink: '' })
    }
  }, [ active ])

  if(p.href.includes('/#')) {
    p.onClick = () => setGlobal({ homeSection: p.href.replace('/#', '') })
  }
  return <Link {...p} className={`header-link ${active ? 'active' : ''}`} />
}

const Menu = ({ left, width }) => {
  return <div className='menu-container'>
    <div className='menu'>
      <HeaderLink href='/#features'>Features</HeaderLink>
      <HeaderLink href='/#videos'>Videos</HeaderLink>
      <HeaderLink href='/testimonials'>Testimonials</HeaderLink>
      <HeaderLink href='/articles'>Press</HeaderLink>
      <HeaderLink href='/faq'>FAQ</HeaderLink>
      <HeaderLink href='/contact'>Contact</HeaderLink>
      {!getGlobal('user') && <HeaderLink href='/projects'>Login</HeaderLink>}
      <div className='indicator' style={{ left: `${left}px`, width: `${width}px` }} />
    </div>
  </div>
}

export default function Header() {

  const [ open, setOpened ] = useState(true)
  const [ userMenu, setUserMenu ] = useState(false)
  const [ state, setState ] = useSetState({
    left: '0px',
    width: '0px'
  })
  const { left, width } = state

  const { activeLink } = getGlobal()
  useEffect(() => {
    const header = document.querySelector('header')
    if(header) {
      let found = false
      for(const l of header.querySelectorAll('.desktop .header-link')) {
        const { host, protocol } = window.location
        const path = l.href.replace(`${protocol}//${host}`, '')
        if(path == activeLink) {
          found = true
          setState({
            left: l.offsetLeft,
            width: l.offsetWidth
          })
        }
      }
      if(!found) setState({ left: header.querySelector('.header-link').offsetLeft, width: 0 })
    }
  }, [ activeLink ])

  const logout = require('pages/WebApp/UserLogin').logout

  return <>
    <header className={`${open ? 'open' : 'closed'}`}>
      <div className='container'>
        <Link href='/' className='logo'>
          <img src={require('images/logo_trademark.png')} />
        </Link>
        <div className='desktop'>
          <Menu {...{ left, width }}/>
          <div className='user-options'>
            { getGlobal('user')
              ? <div
                className='user-menu'
                onMouseLeave={() => setUserMenu(false)}
                onClick={() => setUserMenu(true)}
              >
                {getGlobal('user').name}
                <DropDown open={userMenu}>
                  <Link href='projects'>My Projects</Link>
                  <a onClick={logout}>Sign Out</a>
                </DropDown>
              </div>
              : <Link className='btn login' href='/features'>Sign Up</Link>
            }
          </div>
        </div>

        <div className='mobile'>
          <span className='fa fa-bars' onClick={() => setGlobal({ hamburgerOpen: !getGlobal('hamburgerOpen') })}/>
          <div className='hamburger-menu' onClick={() => setGlobal({ hamburgerOpen: false })}>
            <Collapsible open={getGlobal('hamburgerOpen')}>
              <div className='mobile-menu'>
                <Menu {...{ left, width }} />
                { getGlobal('user')
                  ? <div className='user-links'>
                    <Link href='projects'>My Projects</Link>
                    <a onClick={logout}>Sign Out</a>
                  </div>
                  : <Link className='btn login' href='/features'>Sign Up</Link>
                }
              </div>
            </Collapsible>
          </div>
        </div>


      </div>
    </header>
    <ScrollTrigger
      repeat
      exactPosition
      offset={-300}
      onReset={() => setOpened(true)}
      onTrigger={() => setOpened(false)}
    />
  </>
}
