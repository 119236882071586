import React from 'react'
import { getGlobal, setGlobal, loadGlobal, createDoc, Spinner,
  updateDoc, deleteDoc, Modal, Collapsible, apiPost, helpers } from 'launchpad'
import _ from 'lodash'
import styled from 'styled-components'
export { notify, NotificationContainer } from './notifications'
export { getUpload, asyncUpload, getBase64, ImageUploader, getThumbUrl } from './upload'
export { confirm, confirmDelete, ConfirmModal } from './confirm'
export { validate, Validator } from './validation'
export { useSetState, useInterval } from './custom-hooks'

let loaders = []
let loadIndicator = null

export const money = m => `$${m.toFixed(2)}`

export function titleCase(string){
  return _.startCase(_.toLower(string))
}

export const startLoad = () => {
  const ts = (new Date).getTime()
  loaders.push(ts)
  if(loadIndicator) loadIndicator.forceUpdate()
  return ts
}

export const stopLoad = (ts) => {
  loaders = loaders.filter(x => x != ts)
  if(loadIndicator) loadIndicator.forceUpdate()
}

const LoadContainer = styled.div`
  pointer-events: none;
  position: fixed;
  bottom: 0px;
  left: 0px;
  height: 300px;
  width: 300px;
  color: white;
  transition: opacity .3s;
  background: linear-gradient(215deg, rgba(240,249,255,0) 37%, rgba(203,235,255,0.22) 56%, rgba(2,1,1,0.7) 97%);
  .fa-spinner {
    position: absolute;
    bottom: 30px;
    left: 30px;
    font-size: 40px;
  }
`

export class LoadIndicator extends React.Component {
  componentDidMount = () => loadIndicator = this
  render = () => <LoadContainer className='load-container' style={{ opacity: loaders.length ? 1 : 0 }}><Spinner /></LoadContainer>
}




//TODO: some of these sections are getting big enough to justify their own files




export function updateDB(collection, key, entry, options){
  let col = getGlobal(collection)
  let existing = col.find(d => d._id == key)
  let operation = null
  if(existing){
    operation = updateDoc(collection, Object.assign({ _id: existing._id, slug: existing.slug }, entry))
  } else {
    operation = createDoc(collection, entry)
  }

  operation.then(() => {
    if(!options.skipReload){
      loadGlobal(collection)
    }
    if(typeof options.complete === 'function'){
      options.complete()
    }
  })
}

let debounceTimer = null
let updated = {}

// debounced updates (consolidates rapid updates to the same object to one request)
export function updateDBAttributes(collection, key, atts, options) {
  if(!updated[collection]){
    updated[collection] = {}
  }
  let item = updated[collection][key]
  if(typeof item === 'undefined' && getGlobal(collection)){
    item = getGlobal(collection)[key]
  }
  if(!item){
    item = {}
  }
  for(let key in atts){
    item[key] = atts[key]
  }
  updated[collection][key] = item

  if(debounceTimer){
    clearTimeout(debounceTimer)
  }
  debounceTimer = setTimeout(() => {
    // push all updates
    for(let col in updated){
      for(let key in updated[col]){
        updateDB(col, key, updated[col][key], { skipReload: options.skipReload })
        delete updated[col][key]
      }
    }
  }, 500)
}


// lookup item in collection by given attribute/value
export function getBy(collection, attr, val){
  let col = getGlobal(collection) || []
  return col.find(d => d[attr] == val)
}

// lookup item in collection by its slug
export function getBySlug(collection, slug, options){
  if(options.exceptions && options.exceptions.includes(slug)) return false
  return getBy(collection, 'slug', slug)
}


// get slug that's unique for given collection based on given string (title)
export function getSlug(title, collection,  options) {
  collection = collection || []
  options = options || {}
  console.log(title)
  let slug = title.trim().replace(/ /g, '-').replace(/[^a-zA-Z_0-9\-\/]/g, "")
  console.log(slug)

  if(options.skipValidation) return slug

  slug = slug.toLowerCase()

  let unique = false
  let increment = 1

  // try appending _2, _3, etc until slug is unique
  while(unique == false){
    let test = slug
    let existing = false
    if(increment > 1){
      test = slug + '-' + increment
    }
    if(collection[0] && typeof collection[0] == 'string'){
      existing = collection.map(l => l.toLowerCase()).includes(test) && !(options.exceptions && options.exceptions.includes(test))

    } else {
      existing = getBySlug(collection, test, options)
    }
    if(existing){
      increment++
    } else {
      unique = true
      return test
    }
  }
}


export const getBrowser = () => {
  var sBrowser, sUsrAg = navigator.userAgent

  // The order matters here, and this may report false positives for unlisted browsers.

  if (sUsrAg.indexOf("Firefox") > -1) {
    sBrowser = "Mozilla Firefox"
  // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
  } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
    sBrowser = "Samsung Internet"
  // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
  } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
    sBrowser = "Opera"
  // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
  } else if (sUsrAg.indexOf("Trident") > -1) {
    sBrowser = "Microsoft Internet Explorer"
  // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
  } else if (sUsrAg.indexOf("Edge") > -1) {
    sBrowser = "Microsoft Edge (Legacy)"
  // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
  } else if (sUsrAg.indexOf("Edg") > -1) {
    sBrowser = "Microsoft Edge (Chromium)"
  // Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.124 Safari/537.36 Edg/91.0.864.64
  } else if (sUsrAg.indexOf("Chrome") > -1) {
    sBrowser = "Google Chrome or Chromium"
  // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
  } else if (sUsrAg.indexOf("Safari") > -1) {
    sBrowser = "Apple Safari"
  // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
  } else {
    sBrowser = "unknown"
  }
  return sBrowser
}

// if(module.hot) module.hot.accept()
