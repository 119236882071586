import React from 'react';
import { Page, Snip, Image, ImageGetter, getGlobal, setGlobal, Input, getSetting,
  setSetting, Modal, DataStore, apiGet, apiPost, deleteDoc, confirm,
  Paginate, ItemForm } from 'launchpad';
import Fade, {FadeBlocks} from 'react-move-stuff';
import { NavLink } from 'react-router-dom';
import {Hero} from 'sections';
import {history} from 'app';
import {getSlug} from 'launchpad/helpers';
import styled from 'styled-components';
import { refreshRoutes, getCachedMap } from 'launchpad/routes'


export const loadPages = async (refresh) => {
  if(!getGlobal('customPages') || refresh){
    const pages = await apiGet('pages')
    setGlobal({customPages: pages})
  }
  return getGlobal('customPages')
}

export const savePage = async (page, options) => {
  options = options || {}
  await apiPost('pages', page)
  const pages = await loadPages(true)
  resetSitemap()
  if(!options.skipRefresh) refreshRoutes()
  return pages
}

export const resetSitemap = () => {
  const router = getGlobal('AutoRoutes')
  return apiPost('/create-site-map', {routes: Object.keys(getCachedMap())})
}

export const deletePage = (page) => {
  return new Promise((resolve, reject) => {
    confirm('Are you sure you want to delete this page? This can\'t be undone.', async () => {
      await deleteDoc('pages', page._id)
      refreshRoutes()
      resolve()
    })
  })
}

export const getPageSlugs = (cb) => {
  return getGlobal('routeTree') ? Object.keys(getGlobal('routeTree').route_map) : []
}

export const getUrl = (url, current) => {
  const existing = getPageSlugs()
  return getSlug(url, getPageSlugs(), {exceptions: [current]})
}




const PFContainer = styled.div`
  .button-row {
    text-align: right;
    margin-top: 30px;
  }
`

class PageForm extends React.Component {
  state = {
    title: '',
    url: '',
    shown: false,
    id: ''
  }

  componentDidMount = () => {
    const p = this.props.page
    if(p){
      this.setState({title: p.title, url: p.url, id: p._id})
    }
  }

  componentDidUpdate = (prevProps) => {
    const {page} = this.props
    if(!page && this.state.id) {
      this.setState({title: '', url: '', id: ''})
    }
    if(page && page._id != this.state.id){
      this.setState({title: page.title, url: page.url, id: page._id})
    }
  }

  addPage = async () => {
    const p = this.props.page
    let { title, url } = this.state
    if(!url.startsWith('/')) url = '/' + url
    let update = { title }
    if(p) {
      update = Object.assign(p, update)
    }
    if(!p || this.state.url != p.url){
      update.url = getUrl(url, p ? p.url : '')
    }
    await savePage(update)
    this.props.onSubmit()
  }

  input = (label, state) => {
    return <Input label={label} value={this.state[state]} onChange={e => this.setState({[state]: e.target.value})} />
  }

  render() {
    const button = !this.props.page
      ? <button onClick={this.addPage}><i className='material-icons'>add</i> Add Page</button>
      : <button onClick={this.addPage}><i className='material-icons'>edit</i> Submit Changes</button>
    return <PFContainer>
      {this.input('Page Title', 'title')}
      {this.input('Page URL', 'url')}
      <div className='button-row'>
        {button}
      </div>
    </PFContainer>
  }
}



const PageManagerContainer = styled.div`
  max-width: 1200px;
  padding: 40px;
  margin: 0 auto;
  h2 {
    margin-bottom: 40px;
    display: flex;
    button {
      margin-left: auto;
      font-size: 16px
      display: inline-flex;
      align-items: center;
    }
  }
  .page-list {
    width: 100%;
  }
  th {
    background: rgba(0,0,0,.03);
  }
  tr {

    &:nth-child(2n) {
      background: rgba(80, 150, 205, .1);
    }
  }
  td, th {
    padding: 10px;
  }
`

export default class PageManager extends React.Component {
  state = {
    new: false
  }

  componentDidMount() {
    if(!getGlobal('is_admin')){
      window.location.href = '/';
    }
    resetSitemap()
    loadPages();
  }

  deletePage = async (p) => {
    await deletePage(p)
    this.paginate.reload()
  }

  render(){
    const pages = (getGlobal('customPages') || []);
    const {editing} = this.state
    return(
      <Page id='page-manager' className='padded'>
        <PageManagerContainer>
          <Modal open={this.state.new} onClose={()=>this.setState({new: false})}>
            <PageForm onSubmit={()=>this.setState({new: false}, this.paginate.reload)}/>
          </Modal>
          <h2>Page Manager <button onClick={()=>this.setState({new: true})}><i className='material-icons'>add</i> Add Page</button></h2>

          <Paginate ref={p => this.paginate = p} collection='pages' columns={p => {
            const url = p.url && p.url.startsWith('/') ? p.url :  `/${p.url}`
            return {
              title: <NavLink to={url}>{p.title}</NavLink>,
              url: <NavLink to={url}>{url}</NavLink>,
              tools: <span className='tools'>
                <button onClick={() => this.setState({editing: p})} className='material-icons'>edit</button>
                <button onClick={() => this.deletePage(p)} className='material-icons'>delete</button>
              </span>
            }
          }} />

          <Modal open={editing} onClose={()=>this.setState({editing: null})}>
            <PageForm onSubmit={()=> this.setState({editing: null}, this.paginate.reload)} page={editing} shown={editing} />
          </Modal>
        </PageManagerContainer>
      </Page>
    )
  }
}

// launchpad_title: Custom Pages
// launchpad_url: admin/pages
// launchpad_type: admin
// launchpad_admin_panel: true
// launchpad_icon: list
// launchpad_noindex: true
