
let projects = []

export const setProjects = p => {
  projects = p
}
export const getProjects = (includeArchived) => {
  return includeArchived ? projects : projects.filter(x => !x.archived)
}


let status = {}
export const setProjectStatus = p => {
  status = p
}
export const getProjectStatus = p => status


export const getUpdatingProjects = () => status && status.loading || []

const defaultStatus = { max: 1000, current: 0 }

export const getLimit = () =>
  status && status.projectData && status.projectData.limit  || defaultStatus
