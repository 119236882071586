import React from 'react'

export function AppStoreButtons({ size }) {
  return <div className={`app-store-buttons ${size}`}>
    <a href='https://itunes.apple.com/us/app/zia-mapper-photo-manager/id1275628532?mt=8'>
      <img src={require('images/ios.png')} />
    </a>
    <a href='https://play.google.com/store/apps/details?id=com.ziamapper'>
      <img src={require('images/play_store_badge.png')} />
    </a>
    <a href='/static/uploads/user-guide.pdf'>
      <img src={require('images/user-guide.png')} />
    </a>
  </div>
}
