import React from 'react';
import {getGlobal, setGlobal, loadGlobal, createDoc, Spinner,
  updateDoc, deleteDoc, Modal, Collapsible, apiPost, helpers } from 'launchpad';
import _ from 'lodash';
import styled from 'styled-components';


/***************************************************
  CONFIRM MODAL
****************************************************/

export const ConfirmModal = (props) => {
  return(
    <Modal id='confirm' open={getGlobal('confirmModal')} onClose={() => setGlobal({confirmAcceptText: '', confirmModal: false})} className='confirm'>
      {getGlobal('confirmMessage')}
      <div className='confirm'>
        <button onClick={() => setGlobal({confirmModal: false})}>Cancel</button>
        <button className='danger'
          onClick={() => {getGlobal('confirmCallback')(); setGlobal({confirmModal: false})}}
          > {getGlobal('confirmAcceptText') || 'Accept'} </button>
      </div>
    </Modal>
  )
}

export function confirmDelete(collection, id, cb) {
  confirm('Are you sure you want to delete this?', () => {
    deleteDoc(collection, id).then(() => {
      if(typeof cb === 'function'){
        cb();
      }
      loadGlobal(collection)
    });
  }, 'Delete')
}

export function confirm(message, callback, accept){
  setGlobal({
    confirmModal: true,
    confirmMessage: message,
    confirmCallback: callback,
    confirmAcceptText: accept
  });
}

/***************************************************
  END CONFIRM MODAL
****************************************************/
