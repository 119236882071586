import React from 'react';
import { Snip, Image, ImageGetter, getGlobal, setGlobal, Input, getSetting,
  setSetting, Modal, DataStore, CheckBox, confirm, Collapsible, Page } from 'launchpad';
import Fade, {FadeBlocks} from 'react-move-stuff'
import { ContentBlock, CTA } from 'sections'
import { savePage } from 'pages/admin/PageManager'
import { Link } from 'widgets'

const Testimonials = DataStore('testimonials')

export const Testimonial = ({testimonial}) => {
  const t = testimonial
  return <blockquote className='testimonial'>
    <p className='text'>
      <span className='fa fa-quote-left' />
      {t.text}
      <span className='fa fa-quote-right' />
    </p>
    <p className='author'>{t.name}</p>
    <p className='title'>{t.title}</p>
    <p className='company'>{t.company}</p>
  </blockquote>
}

export default function TestimonialsList() {
  const testimonials = Testimonials.getAll()
  return <Page id='testimonials'>
    <ContentBlock name='main' />
    <div className='grid-container'>
      <div className='testimonials-container grid c2'>
        {testimonials.map(t => {
          return <Testimonial key={t.id} testimonial={t} />
        })}
      </div>
    </div>
  </Page>
}

// launchpad_title: Testimonials
// launchpad_url: testimonials
