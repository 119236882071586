import React from 'react';
import styled from 'styled-components'

const CollapsiblePanel = styled.div`
  .outer:focus {
    border: 0;
    outline: 0;
  }
  .wrapper {
    overflow: hidden;
    box-sizing: border-box;
    &.transitioning {
      transition: height .5s;
      /*transition-timing-function: cubic-bezier(1,.18,0,1.175);*/
    }
  }

  &.open {
    >.outer>.wrapper {
      overflow: visible;
    }
  }
`

export class Collapsible extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      transitioning: false,
      triggered: false,
      open: this.props.open
    }
  }
  componentDidMount() {
    this.forceUpdate();
  }
  componentDidUpdate(prevProps) {
    if(prevProps.open != this.props.open){
      // if(this.props.open){
      //   this.div.focus()
      // }
      this.setState({triggered: true, open: this.props.open})
      clearTimeout(this.start)
      clearTimeout(this.end)
      this.start = setTimeout(()=>this.setState({transitioning: true}), 10)
      this.end = setTimeout(()=>this.setState({transitioning: false, triggered: false}), 510)
    }
  }
  render() {
    //const { open } = this.props
    const { triggered, transitioning, open } = this.state
    const trueMax = this.inner && this.inner.offsetHeight + 'px';
    let maxHeight = open ? (transitioning ? trueMax : 'auto') : '0px';
    if(triggered && !transitioning){
      maxHeight = open ? '0px' : trueMax;
    }

    return this.inner || true ? (
      <CollapsiblePanel className={'collapsible-panel ' + ((open && !transitioning && !triggered) ? 'open' : '')} >
        <div className='outer' ref={div=>this.div = div} onMouseLeave={this.props.onMouseLeave} tabIndex="0" onBlur={this.props.onMouseLeave}>
          <div className={'wrapper' + (this.state.transitioning ? ' transitioning' : '')} style={{height: maxHeight}}>
            <div {...this.props} className={'inner '+(this.props.className || '')} ref={div => this.inner = div}>
              {this.props.children}
            </div>
          </div>
        </div>
      </CollapsiblePanel>
    ) : '';
  }
}
