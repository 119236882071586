import React from "react";
import ReactDOM from "react-dom";

const defaultProps = {
  style: {},
  clientId: "sb",
  options: {
    currency: "USD"
  },
}

export class PayPalButton extends React.Component {
  state = {isSdkReady: false};

  static defaultProps = defaultProps

  componentDidMount() {
    if(window !== undefined && window.paypal === undefined) {
      this.addPaypalSdk();
    }
    else if(this.props.onButtonReady) {
      this.props.onButtonReady();
    }
  }

  addPaypalSdk = () => {
    const { options, onButtonReady, clientId } = this.props;
    //const options = this.props.options || this.defaultProps.options
    const queryParams = [];

    const params = Object.assign({}, defaultProps.options, options, {clientId})

    // replacing camelCase with dashes
    Object.keys(params).forEach(k => {
      const name = k.split(/(?=[A-Z])/).join("-").toLowerCase();
      queryParams.push(`${name}=${params[k]}`);
    });

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `https://www.paypal.com/sdk/js?${queryParams.join("&")}`;
    script.async = true;
    script.onload = () => {
      this.setState({ isSdkReady: true });
      if (onButtonReady) {
        onButtonReady();
      }
    };
    script.onerror = () => {
      throw new Error("Paypal SDK could not be loaded.");
    };

    document.body.appendChild(script);
  }

  createOrder(data, actions) {
    const {currency, options, orderParameters} = this.props
    const defaults = {
      purchase_units: [{
        amount: {
          currency_code: currency || (options && options.currency) || 'USD',
          value: this.props.amount.toString()
        },
      }]
    }
    return actions.order.create(Object.assign(defaults, orderParameters || {}));
  }

  onApprove(data, actions) {
    return actions.order
      .capture()
      .then((details) => {
        if (this.props.onSuccess) {
          return this.props.onSuccess(details, data);
        }
      })
      .catch((err) => {
        console.error(err)
        if (this.props.onError) {
          return this.props.onError(err);
        }
      });
  }

  render() {
      const {
          amount,
          onSuccess,
          createOrder,
          onApprove,
          style,
      } = this.props;
      const { isSdkReady } = this.state;

      if (!isSdkReady && window.paypal === undefined) {
          return null;
      }

      const Button = window.paypal.Buttons.driver("react", {
          React,
          ReactDOM,
      });

      return (
          <Button
              {...this.props}
              createOrder={
                amount && !createOrder
                    ? (data, actions) => this.createOrder(data, actions)
                    : (data, actions) => createOrder(data, actions)
              }
              onApprove={
                  onSuccess
                      ? (data, actions) => this.onApprove(data, actions)
                      : (data, actions) => onApprove(data, actions)
              }
              style={style}
          />
      );
  }
}
