import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  .add-new {
    cursor: pointer;
  }
`

export const Repeater = props => {
    return <Container className='repeater' {...props}>
      {props.items.map((i, idx) => <div className='repeater-item' key={`${i.id}${idx}`}>
        {props.renderItem(i, idx)}
      </div>)}
      {(!props.max || props.items.length < props.max) && <div className='add-new' onClick={props.onAddItem}>
        <i className='fa fa-plus'/> {props.newLabel || 'Add New Item'}
      </div>}
    </Container>
}
