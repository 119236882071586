import React, { useState, useEffect } from 'react'
import { setGlobal, getGlobal, notify } from 'launchpad'

export const getPhotos = images => {
  return new Promise(resolve => {
    setGlobal({ selectingPhotos: true, selectingPhotoSet: images, selectingPhotoCallBack: resolve })
  })
}

export const PhotoSelector = () => {
  const [ selected, setSelected ] = useState([])
  const { selectingPhotos, selectingPhotoSet, selectingPhotoCallBack } = getGlobal()

  useEffect(() => {
    setGlobal({ selectedPhotos: [] })
  }, [])

  useEffect(() => {
    setSelected([])
  }, [ JSON.stringify(selectingPhotoSet) ])

  const commitSelection = () => {
    const selectedPhotos = selectingPhotoSet.filter(p => selected.includes(p.imageId))
    if(!selectedPhotos.length) return notify('Please select at least one photo to continue')
    selectingPhotoCallBack(selectedPhotos)
    setGlobal({ selectingPhotos: false, selectedPhotos })
  }

  const selectPhoto = p => {
    if(selected.includes(p.imageId)){
      setSelected(selected.filter(x => x != p.imageId))
    } else {
      setSelected(selected.concat([ p.imageId ]))
    }
  }

  const selectAll = () => setSelected(selectingPhotoSet.map(p => p.imageId))

  const allSelected = selectingPhotoSet && selectingPhotoSet.length == selected.length

  return <div className={`photo-selector ${selectingPhotos && 'open'}`}>
    <div className={`photo-selector-overlay ${selectingPhotos && 'open'}`}>
      <div className='photo-selector-wrapper'>
        <h4>Select Photos <div
          className='select-all'
          onClick={allSelected ? () => setSelected([]) : selectAll}
        >
          {!allSelected ? 'Select All' : 'Deselect All'}
        </div></h4>

        <div className='photo-selector-photos'>
          {selectingPhotoSet && selectingPhotoSet.map(p => {
            const active = selected.includes(p.imageId)
            return <div
              key={p.imageId}
              className={`selectable-photo image-cover ${active && 'active'}`}
              style={{ backgroundImage: `url(${p.thumbnail || p.url})` }}
              onClick={() => selectPhoto(p)}
            />
          })}
        </div>
        <div className='dialog-buttons'>
          <button onClick={() => setGlobal({ selectingPhotos: false })}>Cancel</button>
          <button className='proceed' onClick={commitSelection}>
            Use Selected <span className='fa fa-chevron-right' />
          </button>
        </div>
      </div>
    </div>
  </div>
}
