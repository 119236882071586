import React, { useState, useEffect } from 'react'
import { pageComponents, getGlobal, AdminLogin, Loading } from 'launchpad'
import { Route, Switch } from 'react-router-dom';
import { route_map, route_components, routesLoaded, refreshRoutes } from './routes'
import { refreshFunctions, customRoutes } from 'config/routes'
import Error404 from 'pages/Error404.jsx';
import Stub from '../src/components/templates/Stub'
import LiveDocs from 'launchpad/docs/live';

export default function AutoRoutes() {
  const [ loaded, setLoaded ] = useState(false)

  useEffect(() => {
    refreshRoutes()
    setTimeout(() => window.prerenderReady = true, 2000)
  }, [])

  useEffect(() => {
    if(routesLoaded) setLoaded(true)
  }, [routesLoaded])

  useEffect(() => {

  }, [loaded])

  const { routeTree } = getGlobal()


  let route_components = {}
  const getRouteComponent = (route) => {
    if(typeof route == 'string'){

      // get component to render from page index (built in code splitting)
      const Renderer = pageComponents[route]
      return () => <Renderer fallback={<main><Loading active={true} fadeIn /></main>}/>

      // may revisit, suspense API seems immature at the moment
      return () => <Suspense fallback={<main><Loading active={true} /></main>}><Renderer key={route} /></Suspense>

    } else if(typeof route == 'function') {
      return route
    } else {
      if(route.component){
        return getRouteComponent(route.component)
      } else {
        return route
      }
    }
  }


  Object.keys(route_map).forEach(key => {
    const route = route_map[key]
    route_components[key] = getRouteComponent(route)
  })

  let Routes = Object.keys(route_components).map((route, i) => {
    let props = {}
    if(typeof route_components[route] != 'function'){
      props.component = route_components[route]
    } else {
      props.render = route_components[route]
    }
    return <Route key={route+i} exact={!route.includes(':')} path={route} {...props} />
  });

  // concatenate custom routes, automated routes and basic launchpad routes
  Routes = customRoutes.map(x => React.cloneElement(x, { key: x.props.path })).concat(Routes).concat([
    <Route key='admin-login' exact path="/admin" component={AdminLogin}/>,
    (getGlobal('is_admin') ? <Route key='launchpad-docs' exact path="/launchpad/docs" component={LiveDocs}/> : <Route key='dummy-route' exact path='/dummy/route' />),
    // it's important that this route is always at the end!!
    <Route key='404' component={Error404} />
  ])

  if(!loaded) return <main><Loading active={true} /></main>
  return <Switch>
    {Routes}
  </Switch>
}
