import { getGlobal, PageContext, DataStore, Image, Snip, Modal, Slider } from 'launchpad';
import ContentEditable from 'react-contenteditable';
import React from 'react';
import styled from 'styled-components';
import Fade from 'react-move-stuff';
import { OptionsPanel, LayoutSelector, Section } from 'sections';
import _ from 'lodash';

const BlockOptions = DataStore('ContentBlockOptions');

const Container = styled.div`
  position: relative;

  &:first-of-type {
    margin-top: -30px;
  }

  .inner {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    padding: 60px 0px;

    > * {
      flex: 1;
      padding: 20px;
    }
    .image-container {
      > * {
        width: calc(100% - 40px);
      }
      img {
        width: 100%;
      }
    }

    .snip-container {
      padding: 0px 30px;
    }
  }

  &.top-image {
    .inner {
      display: block;
      > * {
      }
    }
  }
  &.no-text {
    .inner {
      align-items: center;
      justify-content: center;
      .editable-image {
        display: block;
        margin: 0 auto;
      }
    }
  }

  &.is_admin {
    .editable-image.empty {
      img {
        padding-bottom: 300px;
      }
    }
    .snip-container {
      min-height: 80px;
    }
  }

  @media only screen and (max-width: 812px) {
    .inner {
      display: block;
      .image-container > * {
        width: 100%;
        margin: 0 auto;
      }
    }
  }

  .edit-button {
    position: absolute;
    bottom: 15px;
    right: 15px;
    font-size: 1rem;
    button {
      font-size: 1.7rem;
    }
  }
`;

const OptionsContainer = styled.div`
  text-align: center;
  input {
    margin-right: 30px;
  }
  .width-slider {
    display: flex;
    width: 100%;
    max-width: 600px;
    margin: 30px auto;
    label {
      font-weight: bold;
    }
    .slider-container {
      flex: 1;
    }
  }
`;

/*
  launchpad component: ContentBlock
  categories: sections

  Generic, user-configurable content block, typically only a `name` prop is necessary

  props:
    name - (String) must be different from other content blocks in the same page context
    page - (String) typically passed implicitly, if placed inside a <Page> component, this property is redundant
*/
class Class extends React.Component {
  state = {
    edit: false,
    imageWidth: 0,
  };

  edit = obj => {
    const updated = Object.assign({}, this.props.options, obj);
    this.props.onEdit(updated);
  };

  getOptions = () => {
    const o = BlockOptions.getAll();
    let options = o.find(op => {
      return op.page == this.props.page && op.name == this.props.name;
    });
    return options || { page: this.props.page, name: this.props.name };
  };

  updateBlock = options => {
    const currentOptions = this.getOptions();
    let newOptions = Object.assign({}, currentOptions, options);
    BlockOptions.update(currentOptions.id, newOptions);
  };

  update = _.debounce(this.updateBlock, 200);

  setOptions = options => {
    if (options.imageWidth) this.setState({ imageWidth: options.imageWidth });
    this.update(options);
  };

  render() {
    const options = this.getOptions();
    const { edit, imageWidth } = this.state;
    const layout = (options && options.layout) || this.props.defaultLayout || 'left-image';
    const width = imageWidth || options.imageWidth || 50;
    const imgWidth = layout == 'right-image' ? 100 - width : width;

    const imgStyle =
      layout == 'top-image' ? { width: imgWidth * 0.8 + 20 + '%', margin: '0 auto' } : { flex: imgWidth / 100 + 0.4 };
    const snipStyle = (['top-image', 'no-image']).includes(layout) ? {} : { flex: 1 - imgWidth / 100 + 0.4 };

    const image = (
      <div key="image" style={imgStyle} className="image-container">
        <Image img page={this.props.page} name={this.props.name} />
      </div>
    );
    const snip = (
      <div className="text" style={snipStyle}>
        <Snip key="text" page={this.props.page} name={this.props.name} />
      </div>
    );
    const layouts = {
      'left-image': (
        <>
          {image} {snip}
        </>
      ),
      'top-image': (
        <>
          {image} {snip}
        </>
      ),
      'no-image': <>{snip}</>,
      'right-image': (
        <>
          {snip} {image}
        </>
      ),
      'no-text': <>{image}</>
    };

    return (
      <Fade up>
        <Section editing={this.state.edit} className={`content-block ${this.props.className || ''}`}>
          <Container className={layout + (getGlobal('is_admin') ? ' is_admin' : '')}>
            <div className="inner">
              {layouts[layout]}
              {getGlobal('is_admin') && (
                <div className="edit-button">
                  <button className="material-icons" onClick={() => this.setState({ edit: !edit })}>
                    {edit ? 'close' : 'edit'}
                  </button>
                </div>
              )}
            </div>
          </Container>
        </Section>
        {getGlobal('is_admin') && (
          <OptionsPanel open={this.state.edit} onClose={() => this.setState({ edit: false })}>
            <h3>Content Block Options</h3>
            <LayoutSelector
              options={Object.keys(layouts)}
              value={layout}
              onChange={l => this.setOptions({ layout: l })}
            />
            <OptionsContainer>
              <div className="width-slider">
                <label>Layout Balance</label>
                <Slider responsive onChange={v => this.setOptions({ imageWidth: v })} value={width} />
              </div>
            </OptionsContainer>
          </OptionsPanel>
        )}
      </Fade>
    );
  }
}

export const ContentBlock = props => (
  <PageContext.Consumer>{context => <Class {...props} page={props.page || context.pageId} />}</PageContext.Consumer>
);
