import React from 'react';
import { registerRefreshFunction, registerCustomRoutes } from 'launchpad/routes';
import { Route, Redirect } from 'react-router-dom';
import { loadPages } from 'pages/admin/PageManager';
import global_config from '../../global_config';


/* ==========================================
    DEFINE CONFIG HERE
   ========================================== */

const config = {
  // core configuration vars
};

// combine with global config and export
const combinedConfig = Object.assign({}, global_config, config);
export default combinedConfig;
