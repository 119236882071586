
// ===============  AUTOMATICALLY GENERATED  =======================
// Any edits made to this file will be overwritten on the next build
// See scripts/build-scripts/index-pages.js for more information
  
import React from 'react'
// import Loadable from 'react-loadable'
import loadable from '@loadable/component'

export const pageList = [
  "Articles",
  "Contact",
  "EULA",
  "FAQ",
  "Features",
  "Home",
  "Login",
  "Privacy",
  "ResetPassword",
  "Testimonials",
  "UserDashboard",
  "WebApp/Registration",
  "WebApp/index",
  "admin/ArticleManager",
  "admin/TestimonialManager",
  "admin/UserManager"
];

export const pageIds = [
  {
    "url": "/articles",
    "id": "articles",
    "title": "Articles"
  },
  {
    "url": "/contact",
    "id": "contact",
    "title": "Contact"
  },
  {
    "url": "/terms",
    "id": "eula",
    "title": "Terms of Service"
  },
  {
    "url": "/faq",
    "id": "faq",
    "title": "Faq"
  },
  {
    "url": "/features",
    "id": "features",
    "title": "Features"
  },
  {
    "url": "/home",
    "id": "home",
    "title": "Home"
  },
  {
    "url": "/login",
    "id": "login",
    "title": "Login"
  },
  {
    "url": "/privacy",
    "id": "privacy",
    "title": "Privacy"
  },
  {
    "url": "/reset-password",
    "id": "reset-password",
    "title": "Reset Password"
  },
  {
    "url": "/testimonials",
    "id": "testimonials",
    "title": "Testimonials"
  },
  {
    "url": "/user-dashboard",
    "id": "user-dashboard",
    "title": "User Dashboard"
  },
  {
    "url": "/registration",
    "id": "registration",
    "title": "Registration"
  },
  {
    "url": "/projects",
    "id": "web-app",
    "title": "Web App"
  },
  {
    "url": "/admin/articles",
    "id": "page-manager",
    "title": "Article Manager"
  },
  {
    "url": "/admin/testimonials",
    "id": "testimonial-manager",
    "title": "Testimonials Manager"
  },
  {
    "url": "/admin/users",
    "id": "user-manager",
    "title": "Users"
  }
];

export const pageMap = {
  "directory": "/",
  "children": [
    {
      "title": " Articles",
      "href": "Articles"
    },
    {
      "title": " Contact",
      "href": "Contact"
    },
    {
      "title": " E U L A",
      "href": "EULA"
    },
    {
      "title": " F A Q",
      "href": "FAQ"
    },
    {
      "title": " Features",
      "href": "Features"
    },
    {
      "title": " Home",
      "href": "Home"
    },
    {
      "title": " Login",
      "href": "Login"
    },
    {
      "title": " Privacy",
      "href": "Privacy"
    },
    {
      "title": " Reset Password",
      "href": "ResetPassword"
    },
    {
      "title": " Testimonials",
      "href": "Testimonials"
    },
    {
      "title": " User Dashboard",
      "href": "UserDashboard"
    },
    {
      "directory": "WebApp",
      "children": [
        {
          "title": " Gold Registration",
          "href": "WebApp/Registration"
        },
        {
          "title": "Index",
          "href": "WebApp/index"
        }
      ]
    },
    {
      "directory": "admin",
      "children": [
        {
          "title": " Article Manager",
          "href": "admin/ArticleManager"
        },
        {
          "title": " Testimonial Manager",
          "href": "admin/TestimonialManager"
        },
        {
          "title": " User Manager",
          "href": "admin/UserManager"
        }
      ]
    }
  ]
};

export const pageProps = {
  "Articles": {
    "id": "articles",
    "launchpad_url": "/articles",
    "url": "/articles"
  },
  "Contact": {
    "id": "contact",
    "url": "/contact"
  },
  "EULA": {
    "id": "eula",
    "launchpad_url": "terms",
    "launchpad_title": "Terms of Service",
    "url": "/terms"
  },
  "FAQ": {
    "id": "faq",
    "launchpad_url": "faq",
    "url": "/faq"
  },
  "Features": {
    "id": "features",
    "url": "/features"
  },
  "Home": {
    "id": "home",
    "url": "/home"
  },
  "Login": {
    "id": "login",
    "className": "about-page",
    "url": "/login"
  },
  "Privacy": {
    "id": "privacy",
    "url": "/privacy"
  },
  "ResetPassword": {
    "id": "reset-password",
    "className": "about-page",
    "launchpad_url": "reset-password",
    "launchpad_title": "Reset Password",
    "url": "/reset-password"
  },
  "Testimonials": {
    "id": "testimonials",
    "launchpad_title": "Testimonials",
    "launchpad_url": "testimonials",
    "url": "/testimonials"
  },
  "UserDashboard": {
    "id": "user-dashboard",
    "className": "container",
    "launchpad_url": "/user-dashboard",
    "url": "/user-dashboard"
  },
  "WebApp/Registration": {
    "id": "registration",
    "className": "login",
    "launchpad_url": "/registration",
    "url": "/registration"
  },
  "WebApp/index": {
    "id": "web-app",
    "launchpad_url": "projects",
    "url": "/projects"
  },
  "admin/ArticleManager": {
    "id": "page-manager",
    "className": "padded",
    "launchpad_title": "Article Manager",
    "launchpad_url": "admin/articles",
    "launchpad_type": "admin",
    "launchpad_admin_panel": "true",
    "launchpad_icon": "list",
    "url": "/admin/articles"
  },
  "admin/TestimonialManager": {
    "id": "testimonial-manager",
    "className": "padded",
    "launchpad_title": "Testimonials Manager",
    "launchpad_url": "admin/testimonials",
    "launchpad_type": "admin",
    "launchpad_admin_panel": "true",
    "launchpad_icon": "comments",
    "url": "/admin/testimonials"
  },
  "admin/UserManager": {
    "id": "user-manager",
    "className": "padded",
    "launchpad_title": "Users",
    "launchpad_url": "admin/users",
    "launchpad_admin_panel": "true",
    "launchpad_type": "admin",
    "launchpad_icon": "user",
    "url": "/admin/users"
  }
};

export const pageLinks = pageIds.map(page => page.url)

export const pageComponents = {
    "Articles": loadable(() => import("pages/Articles.jsx")),
    "Contact": loadable(() => import("pages/Contact.jsx")),
    "EULA": loadable(() => import("pages/EULA.jsx")),
    "FAQ": loadable(() => import("pages/FAQ.jsx")),
    "Features": loadable(() => import("pages/Features.jsx")),
    "Home": loadable(() => import("pages/Home.jsx")),
    "Login": loadable(() => import("pages/Login.jsx")),
    "Privacy": loadable(() => import("pages/Privacy.jsx")),
    "ResetPassword": loadable(() => import("pages/ResetPassword.jsx")),
    "Testimonials": loadable(() => import("pages/Testimonials.jsx")),
    "UserDashboard": loadable(() => import("pages/UserDashboard.jsx")),
    "WebApp/Registration": loadable(() => import("pages/WebApp/Registration.jsx")),
    "WebApp/index": loadable(() => import("pages/WebApp/index.jsx")),
    "admin/ArticleManager": loadable(() => import("pages/admin/ArticleManager.jsx")),
    "admin/TestimonialManager": loadable(() => import("pages/admin/TestimonialManager.jsx")),
    "admin/UserManager": loadable(() => import("pages/admin/UserManager.jsx")),
}


// ===============  AUTOMATICALLY GENERATED  =======================
// Any edits made to this file will be overwritten on the next build
// See scripts/build-scripts/index-pages.js for more information
  