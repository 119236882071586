import React from 'react'
import { Snip, Image, DynamicMenu, helpers, getSetting } from 'launchpad'
import { Link } from 'widgets'
import { TwitterTimelineEmbed } from 'react-twitter-embed'


const socialLinks = {
  facebook: '',
  twitter: '',
  linkedin: 'LinkedIn',
  instagram: '',
  youtube: 'YouTube',
  pinterest: ''
}

const SocialLink =  ({ type, title, icon }) => {
  icon = icon || type
  title = title || helpers.getTitle(type)
  return getSetting(type) ? <div key={type} className='social-link'>
    <a href={getSetting(type)} className={type} target="_blank">
      <span className={`fa fa-${icon}`} />
      {/* {title} */}
    </a>
  </div> : ''
}

export default function Footer() {
  return <footer>
    <div className='footer-container grid c3'>

      <div className='about'>
        <h3>About ZIA</h3>

        <Snip page='footer' name='about-zia' />

        <div className='links'>
          <Link href='/privacy'>Privacy Policy</Link> <Link href='/terms'>Terms Of Service</Link>
        </div>
      </div>

      <div className='contact'>
        <h3>Get in touch</h3>
        <p className='title'>{getSetting('company_name')}</p>
        <p>{getSetting('street_address')} &nbsp;|&nbsp; {getSetting('city_&_zip')}</p>

        {getSetting('phone') && <p className='title'>PH {getSetting('phone')}</p>}

        <p><Link href='/contact'>Contact Us</Link></p>

        <div className='social'>
          {Object.keys(socialLinks).map(
            key =>
            <SocialLink key={key} type={key} title={socialLinks[key]}/>
          )}
        </div>
      </div>

      <div className='twitter'>
        <h3>Twitter Feed</h3>
        <div className='tweet-container'>
          <TwitterTimelineEmbed
            sourceType='profile'
            screenName='ZiaMapper'
            theme='dark'
            transparent
            noHeader noFooter noBorders cardsHidden
            linkColor='#b92828'
            options={{ tweetLimit: '3', cardsHidden: 'hidden' }}
          />
        </div>
      </div>
    </div>


    <div className='copyright'>
        Copyright &copy; {new Date().getFullYear()} {getSetting('company_name')}
    </div>
  </footer>
}
