import React, { useState } from 'react'

export default function AutoFrame({ src }) {
  const [ height, setHeight ] = useState(0)
  const size = e => {
    setHeight(e.target.contentWindow.document.body.scrollHeight)
  }

  return <iframe style={{ height: `${height}px` }} onLoad={size} scrolling="no" src={src}/>
}
